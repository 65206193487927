import React, { useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonToast, IonAlert, isPlatform } from '@ionic/react';
import Details, {validator as detailsValidator} from './Details';
import stepQuestions from './StepQuestions';
import StepOptionQuestion, { validator as stepOptionQuestionValidator} from './StepOptionQuestion';
import { Survey } from '../../data/Survey';
import { IGlobalState } from '../../store/IGlobalState';
import api from '../../data/api';
import Photos, { validator as photosValidator} from './Photos';
import Photos2 , { validator as photos2Validator} from './Photos2';
import WristIrritability, { validator as wristIrritabilityValidator} from './WristIrritability';
import Diagnosis, { validator as diagnosisValidator} from './Diagnosis';
import { useHistory } from "react-router-dom";
import { StartSaveAction } from '../../actions/SurveyActions';
import CalculatingDiagnosisStep from './CalculatingDiagnosisStep';
import { Role } from '../../data/Role';
import { IUser } from '../../data/IUser';
import SurveyButtonPanel from './SurveyButtonPanel';


const arr = stepQuestions.map((q,index)=> (
  { 
    node: <StepOptionQuestion stepQuestion={q} key={index} />, 
    validator:(s:Survey)=> stepOptionQuestionValidator(s, q)
  }))


const getStepComponents:(user:IUser, isBeta:boolean)=>{node:ReactNode, validator:(survey:Survey)=>string|undefined}[]  = (user:IUser, isBeta:boolean) =>  {
  const isDesktop = isPlatform('desktop')
  const steps = [    
    
    { node: <Details />, validator:detailsValidator },
    { node: isDesktop ? <Photos /> : <Photos2 />, validator: isDesktop ? photosValidator : photos2Validator},  
    ...arr,
     
    { node: <WristIrritability />, validator:wristIrritabilityValidator },
     
  ];
  if(isBeta){
    steps.push({ node: <CalculatingDiagnosisStep />, validator:(s)=> undefined } )
  }else{
    steps.push({ node: <Diagnosis />, validator:diagnosisValidator })
  }
  
    return steps;  
}

const SurveyComponent: React.FC = () => {
  const user = useSelector((state:IGlobalState)=>state.user);
  const survey = useSelector((state:IGlobalState)=>state.survey);
  const isOnline = useSelector((state:IGlobalState)=>state.isOnline);
  const isBeta = !!user.roles?.includes(Role.Beta);
  const [stepComponents ] = useState(getStepComponents(user, isBeta));
  const history = useHistory();
  const [step, setStep ] = useState(0);
  
  const [showToast, setShowToast] = useState(false);
  const [saving, setSaving] = useState(false);
  const [stepValidationError, setStepValidationError] = useState('');
  const isCameraActive = useSelector((state:IGlobalState)=>state.isCameraActive);
  const dispatch = useDispatch();
  //

  const save = async () => {
    await dispatch(StartSaveAction());
    //api.saveSurvey(props.survey!);
  }

  if(!survey)
    return <div></div>;
  
  const nextPrevClicked = async (next:boolean) =>{
    setStepValidationError('')
    let validationError:string|undefined;
    if(next){
      validationError = stepComponents[step].validator(survey);
    }
    if(!isOnline){
      alert('Cannot save whilst offline')
      return
    }
    
    if(!validationError){          
      setSaving(true)
      await save()            

      if(next && step === stepComponents.length-1){
        if(!isBeta){
          api.setSurveyReadonly(survey.id);
          setShowToast(true)
        }
        
      }else{
        setStep(step + (next ? 1 : -1))
      }
      setSaving(false)
    }else{
      setStepValidationError(validationError)
    }
  }

  
  
  let nextButtonText = 'Next';
  let prevButtonText = 'Prev';
  
  if(isBeta){
    if(step === stepComponents.length-2 && !survey.readonly){      
      nextButtonText = 'Submit';
    }else if(step === stepComponents.length-1){
      nextButtonText = '';
      if(!survey.readonly){
        prevButtonText = '';
      }      
    }
  }else{
    if(step === stepComponents.length-1){
      nextButtonText = 'Submit';
    }
  }
  
  const style = isCameraActive ? {} : { padding: '15px'};

  return (
    <div className="survey-step-page" 
    style={style}
      key={survey?.id}
      >
        { !isOnline && <p style={{backgroundColor:"red", color:"white", textTransform:"uppercase", textAlign:"center", padding:"10px 0"}}>Network is disconnected!</p>}
        { stepComponents[step].node }

        { !isCameraActive && <SurveyButtonPanel step={step} numSteps={stepComponents.length} nextPrevClicked={nextPrevClicked} saving={saving} prevButtonText={prevButtonText} nextButtonText={nextButtonText} />}
        

        <IonAlert
            isOpen={!!stepValidationError}
            onDidDismiss={() => setStepValidationError('')}
            header={'Error!'}
            message={stepValidationError}
            buttons={['OK']}
          />

        <IonToast
        isOpen={showToast}
        onDidDismiss={() => { setShowToast(false); history.push("/surveys");}}
        message="Survey Submitted..."
        position="bottom"
        duration={2000}
      />

    </div>
  );
};



// const areStatesEqual = (next: IGlobalState, prev: IGlobalState) => {    
//   let result = prev.survey === next.survey && prev.isOnline === next.isOnline;           
  
//   return result;
// }
export default SurveyComponent
