import { Survey } from '../data/Survey';
const defaultState:any = null;
// import cloneDeep from 'lodash/cloneDeep';
// const oldMessages = cloneDeep(this.state.messages);

export default (state:Survey = defaultState, action:{type:string, value?:Survey, updates:any}) => {
  
  switch(action.type){    
    case 'SET_SURVEY':       
    return action.value;
    case 'EDIT_SURVEY':    
    
    let survey:Survey = {
      ...state,
      ...action.updates,      
    }

    return survey
    case 'RESET_SURVEY':       
    return null;
    default:
      return state;
  }
}

