import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import environment from '../../util/environment';
import { isStandAlone } from '../../data/util';
import { IonItem, IonIcon, IonLabel, IonList } from '@ionic/react';
import { logOutOutline } from 'ionicons/icons';
import log from '../../util/log';
import api from '../../data/api';
import { connect } from 'react-redux';
import { startLogout } from '../../actions/UserActions';

interface IProps {
    logout: ()=>void;
  }

const AboutComponent = (props:IProps) =>{
    const { logout } = props;
    const history = useHistory();
    const [uploadingLog, setUploadingLog ] = useState<boolean>(false)
    return <IonList>
        <IonItem>
            <IonLabel>Version: {environment.version} standalone: {isStandAlone() ? 'Yes' : 'No'}</IonLabel>
        </IonItem>

        <IonItem routerLink="#" onClick={async (e) => {
            
            
            let json = localStorage.getItem('minilog');
            if(json){
                log.info('sending log')
                setUploadingLog(true)
                let data:any[][] = JSON.parse(json);
                let lines = []
                for(let item of data){
                    lines.push(JSON.stringify(item))
                }
                await api.sendLog( { lines })
                setUploadingLog(false)
                alert('Log sent.')
            }else{
                alert('nothing in log')
            }
            
            
        }} routerDirection="none" lines="none" detail={false}>            
            <IonLabel> { uploadingLog ? 'Uploading...' : 'Send Log File'}</IonLabel>
        </IonItem>

        <IonItem routerLink="#" onClick={async (e) => {
            await logout()
            history.push("/");
        }} routerDirection="none" lines="none" detail={false}>
            <IonIcon slot="start" icon={logOutOutline} />
            <IonLabel>Logout</IonLabel>
        </IonItem>
    </IonList>
    
}

const mapDispatch = {
    logout: () => (startLogout()),
  }
 export default connect(null, mapDispatch)(AboutComponent);
