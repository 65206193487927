import React from 'react';
import { useHistory } from 'react-router';

// import { IonGrid, IonRow, IonCol, IonContent, IonIcon } from '@ionic/react';
// import { Link } from 'react-router-dom'
// import { cameraOutline,addCircleOutline, helpOutline, newspaperOutline } from 'ionicons/icons';



const Dashboard:React.FC  = () => {
  
  const history = useHistory();  

    return <div className='dashboard'>
      <p className='dashboard__scan-new-label'>Click below to scan a new patient</p>

      <div>
        <div className='dashboard__scan-button-outer'>
          <div 
          className='dashboard__scan-button'
          onClick={()=>history.push("/new-survey")}
          >SCAN</div>
        </div>
      </div>

      <div className='dashboard__bottom-panel'>

        <div onClick={()=>history.push("/introduction")}>Introduction</div>
        <div onClick={()=>history.push("/instructions")}>Instructions</div>
        <div onClick={()=>history.push("/photo-guide")}>Photo Guide</div>

      </div>

    </div>
}

export default Dashboard;