import React from 'react';
import StepOptionQuestion, { validator as stepOptionQuestionValidator} from './StepOptionQuestion';
import { StepQuestion, StepQuestionType } from './StepQuestions';
import { Survey } from '../../data/Survey';

const question = new StepQuestion(StepQuestionType.WristIrritability, "Examination Result", [ "Yes", "No" ], 10);

const WristIrritability: React.FC = () => {
  

  return <StepOptionQuestion stepQuestion={question} >
  <p>Wrist irritability (pain on passive wrist flexion)</p>
  <div><img
      src="/assets/images/wrist_irritability_diagram.png"
      alt=""          
    /></div>
    
  </StepOptionQuestion>;
};

export default WristIrritability;


export const validator = (survey:Survey) : string|undefined => stepOptionQuestionValidator(survey, question)