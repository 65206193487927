import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import LoadingComponent from '../../components/LoadingComponent';
import { IUser } from '../../data/IUser';
import NewUserComponent from './NewUserComponent';
import EditUserComponent from './EditUserComponent';
import adminApi from '../../data/adminApi';
import useAsyncEffect from 'use-async-effect';


interface ViewMessageProps extends RouteComponentProps<{ id: string; }> { }

const UserComponent: React.FC<ViewMessageProps> = (props:any) => {

  const history = useHistory();  
  const isNew = props.match.params.id === 'new';
    
    const [ user, setUser ] = useState<IUser|undefined>();
    const [ loading, setLoading ] = useState<boolean>();
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ saving, setSaving ] = useState(false);
    
    const uid = isNew ? '' : props.match.params.id;  
    
    useAsyncEffect(async(isMounted)=>{
      setLoading(true)
      
      
      if(!isNew){
      
        let user = await adminApi.getUser(uid);              
        if(isMounted()){
                  // if(uid !== "9rpJpPsUJOUDlq95TZY1RB4waiC2"){
   
        // }  
          setUser(user)
          setLoading(false)
        }
        }else{
          setLoading(false)
        }
        
        
      }, [isNew, uid ])

     

    const saveUser = async (user:IUser, password:string)=>{
        setErrorMessage('')
        setSaving(true)
        let result = await adminApi.createUser(user, password)   
          
        if(result.id){
          history.push(`/users/${result.id}`);
        }else{
          setErrorMessage(result.errorMessage)
          setSaving(false)
        }
    }

  return (
      <>

         {loading && <LoadingComponent />}
          { isNew && <NewUserComponent onSave={saveUser} saving={saving} />}
          { !isNew && user && <EditUserComponent user={user} />}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>} 

      </>
  );
};


  
  export default UserComponent;

  