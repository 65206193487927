import React from 'react';
import { IonAlert } from '@ionic/react';
import { IUser } from '../../data/IUser';

interface IProps {    
    showAlert:boolean
    setShowAlert:(show:boolean)=>void
    user:IUser
    onDeleteConfirmed:() => void;
}

const DeleteUserConfirmationAlert = (props:IProps)=>{
    
    
    
    return <IonAlert
    isOpen={props.showAlert}
    onDidDismiss={() => props.setShowAlert(false)}
    header={'Delete User?'}
    message={`<p>Are you sure you want to delete  </p> <p><strong>${props.user.username}</strong> ?</p><p>This action cannot be undone</p>`}
    buttons={[
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',              
      },
      {
        text: 'Okay',
        handler: () => {
              
              props.onDeleteConfirmed()
              
        }
      }
    ]}
  />
}

export default DeleteUserConfirmationAlert
