import React, { RefObject, useState } from 'react';
import {  IonFabButton, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import { camera } from 'ionicons/icons';
import { firstFileToBase64, localImageStoragePath, deleteImage } from '../../data/util';
import { Survey } from '../../data/Survey';
import { IGlobalState } from '../../store/IGlobalState';
import { StartEditAndSaveSurvey, DeleteSurveyImage } from '../../actions/SurveyActions';
import { connect, useSelector } from 'react-redux';
import api from '../../data/api';
import uuid from 'short-uuid';
import PhotoComponent from './PhotoComponent';
import * as storage from '../../data/storage';
import 'react-image-crop/dist/ReactCrop.css';
import CroppableImage from '../CroppableImage';
import log from '../../util/log';
import { Role } from '../../data/Role';
import { Photo } from '../../hooks/usePhotoGallery';
import useAsyncEffect from 'use-async-effect';

interface IProps {
  survey?:Survey
  editSurvey:(updates:any, forceSave?:boolean)=>void;
  deleteImage:(imageName:string, forceSave?:boolean)=>void;
}




const Photos: React.FC<IProps> = (props:IProps) => {
  
  const survey = props.survey!;
  const pwaphoto = React.createRef() as RefObject<HTMLInputElement>; 
  const [ cropping, setCropping ] = useState<{url:string, name:string}[]>([])
  const user = useSelector((state:IGlobalState)=>state.user);
  const [ otherImages, setOtherImages ] = useState<firebase.storage.Reference[]>([])
  const isAdmin = user.roles?.includes(Role.ReadAllSurveys)

  const openPWAPhotoPicker = ()=>{
    (pwaphoto.current as HTMLInputElement).click();
  }
  const uploadPWA = async ()=>{
    const fileList  = (pwaphoto.current as HTMLInputElement).files as FileList;

    if (fileList && fileList.length > 0) {
      const file = fileList[0]
      let imageName = uuid.generate();
      let uri = await firstFileToBase64(file) as string;
      
      try {                
        await storage.setItem<string>(localImageStoragePath(imageName), uri)                
      } catch (error) {
        alert(error)
      }
      
      const meta = { contentType:file.type,customMetadata:{ initials: survey.initials }  }
      api.uploadFile(fileList[0], { image: imageName, surveyId: survey.id, meta, userId: survey.uid})
      props.editSurvey!( { images: [ ...survey.images, imageName ]})
    }
  }

  const saveCrop = async (file:File, originalCropImageName:string)=>{    
    log.info(`saveCrop. new image:${file.name}. original image: ${originalCropImageName}`)
    
    //save image to storage so it can be read by PhotoComponent prior/during upload
    let uri = await firstFileToBase64(file) as string;
    const path = localImageStoragePath(file.name)
    storage.setItem<string>(path, uri)        
    
    const images = []    
    //replace image at same location as original
    for(let image of survey.images){
      images.push(image === originalCropImageName ? file.name : image)      
    }
       
    api.uploadFile(file, { image: file.name, surveyId: survey.id, meta : { contentType:'image/jpeg' }, userId: survey.uid })
    props.editSurvey!( { images }, true)
    setCropping( cropping.filter(x=>x.name !==originalCropImageName ))
    
}


const allowDelete = ()=>{
  
  return isAdmin || !props.survey!.readonly
}

const onDelete = (p:Photo) => {
  if(props.survey!.readonly){
    if(window.confirm('22 Are you sure you want to delete this image?')){
      props.deleteImage(p.filepath!, true);
    }

  }else{
    deleteImage(p, survey, props.deleteImage)
  }
}


useAsyncEffect(async (isMounted)=> {
  if(user.roles?.includes(Role.ReadAllSurveys)){
    let result = await api.getSurveyImages(survey.id, survey.uid);
    if(isMounted()){
      const arr:firebase.storage.Reference[] = [];
      for(let listResult of result.items){
        let found = false;
        for(let image of survey.images){
          if(listResult.fullPath.endsWith(image)){
            found =true;
          }
        }
        if(!found && !listResult.name.includes("_")){
          arr.push(listResult)
        }
      }
      setOtherImages(arr);
    }
  }
  

}
, [ survey ]);


const restoreImage = (name:string)=>{
  // console.log('survey.images', survey.images)
  // console.log('name', name)
  props.editSurvey!( { images: [ ...survey.images, name ]})
}

  return <>
   

   <div className="photos-step">
      <div className="photos-step__info">
        <p>Please include one photo EACH of the <em>right and left hand</em>, on a white background with the fingers splayed and wrist visible. Please remove bracelets/watches where possible</p>
      </div>
      <div className="photos-step__selector">
      <IonFabButton disabled={survey.readonly} onClick={() => openPWAPhotoPicker()}>
          <IonIcon icon={camera}></IonIcon>
      </IonFabButton>
      </div>

      <div id="pwa">
        <input type="file"
          id="pwaphoto"
          ref={pwaphoto}
          accept="image/x-png,image/jpeg"
          onChange={() => uploadPWA()}
          style={{
            visibility: 'hidden'
          }}
        />
      </div>

      <IonGrid>
        <IonRow>
          {survey.images.map((image, index) => (
            <IonCol size="6" key={index} style={{textAlign:'center'}}>
              
              { cropping.filter(x=>x.name===image).length===0 && 
              <>
              
              <PhotoComponent 
              photo={{filepath:image}} 
              surveyId={survey.id} 
              userId={survey.uid} 
              onDelete={onDelete} 
              allowDelete={allowDelete()}  
              onCropImage={(p)=> {
                setCropping([ ...cropping,  { url: p, name: image} ])                
              }}
              />
              </>
              
              }

              { cropping.filter(x=>x.name===image).length > 0 && <>
                
                <CroppableImage 
                src={cropping.find(x=>x.name===image)!.url}                
                saveCrop={(f)=>saveCrop(f, image)}
                cancelCrop={()=> setCropping(cropping.filter(x=>x.name !==image )) }
                />
              </>
              
              }

              
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
            
              { isAdmin && otherImages.length > 0 && <div>
                <p>Other Images</p>
                <ul>

                
                { otherImages.map((x,i)=> <li key={i}><span>{x.name}</span>  <button onClick={(e)=> restoreImage(x.name)}>Restore</button></li>)}
                </ul>
                </div>}


    </div>
  </>

  
};

const mapStateToProps = (state:IGlobalState)  => {
  
  return {
    survey: state.survey
  }
}
const mapDispatch = {
  editSurvey:(updates:any, forceSave?:boolean)=> (StartEditAndSaveSurvey(updates, forceSave)),
  deleteImage:(imageName:string, forceSave?:boolean)=> (DeleteSurveyImage(imageName, forceSave))
}

export default connect(mapStateToProps, mapDispatch)(Photos);


export const validator = (survey:Survey) : string|undefined =>  {
  if(!survey.images || survey.images.length < 2){
    return 'Please provide at least 2 photos';    
  }
}