import React, { useEffect } from 'react';
import {  useDispatch } from 'react-redux';
import SurveyComponent from './SurveyComponent';
import { NewSurveyAction } from '../../actions/SurveyActions';


const NewSurvey: React.FC = () => {
  const pathname = window.location.pathname;
  const dispatch = useDispatch();
  useEffect(()=>{
    if(pathname === '/new-survey'){
      console.log('dispatch NewSurveyAction', `pathname:${pathname}`)
      dispatch(NewSurveyAction())    
    }
    
  }, [ dispatch, pathname ])

  return <SurveyComponent />;
};



export default NewSurvey;
