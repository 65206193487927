export default (state:string = '', action:{type:string, value:string}) => {
  
  switch(action.type){    
    case 'SET_VERSION':       
    return action.value;
    
    default:
      return state;
  }
}

