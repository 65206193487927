import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import  { firebase } from './data/firebase';
//import api from './data/api';
import './styles/styles.scss';
import LoadingComponent from './components/LoadingComponent';
import store from './store/configureStore';
import { SetUser } from './actions/UserActions';
import api from './data/api';
import { resetSurveys } from './actions/SurveysActions';
import environment from './util/environment';
import { SwUpdate } from './reducers/serviceWorker';
import adminApi from './data/adminApi'
import { Role } from './data/Role';
import { getRoles } from './data/util';
import checkOfflineUploads from './data/checkOfflineUploads';
import { Plugins } from '@capacitor/core';
import log from './util/log';
import { SetIsOnlineAction } from './actions/IsOnlineAction';
import { getPlatforms, setupIonicReact } from '@ionic/react';
//import '@capacitor-community/camera-preview'




window.onerror = function (msg, url, line) {
  log.error("Caught[via window.onerror]: '" + msg + "' from " + url + ":" + line);
};
window.addEventListener('unhandledrejection', function(event) {
  log.error(`Unhandled rejection (promise: ${event.promise} reason ${event.reason}`);
});

setupIonicReact({
  mode: 'md',
  platform: {
    /** The default `desktop` function returns false for devices with a touchscreen.
     * This is not always wanted, so this function tests the User Agent instead.
     **/
    desktop: (win) => {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
      console.log(`custom desktop: isMobile:${isMobile} userAgent: ${win.navigator.userAgent}`)
      return !isMobile;
    },
  },
});
// console.log(`platforms: ${getPlatforms().join(',')} desktop:${isPlatform('desktop')}`)

log.info(`version ${environment.version}`)
const { SplashScreen } = Plugins;
// Hide the splash (you should do this on app launch)
SplashScreen.hide();
ReactDOM.render(<LoadingComponent />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => log.info('serviceWorker onSuccess'),
  onUpdate: reg => store.dispatch(SwUpdate(reg)),
});



firebase.auth().onAuthStateChanged(async (firebaseUser) => {
  store.dispatch(resetSurveys());
  
  if(firebaseUser){    
    
    const tokenResult = await firebaseUser.getIdTokenResult();   
    
    await adminApi.setToken(); 
    localStorage.setItem('lastLoginSuccessEmail', firebaseUser.email!)
    
    if(environment.version !== '__DEV__'){
      api.checkVersion(store);
    }
    
    const roles:Role[] = getRoles(tokenResult.claims);
    const platforms = getPlatforms();
    log.info(`user.email ${firebaseUser.email}. roles: ${roles.join(',')} platforms: ${platforms.join(',')}`)
    await api.subscribeToSurveys(store, roles.includes(Role.ReadAllSurveys));    
    
    
    store.dispatch(SetIsOnlineAction(true))
    store.dispatch(SetUser({ uid:firebaseUser.uid,  username: firebaseUser.email!, roles} ));
    adminApi.cache();    

  }else{
    store.dispatch(SetUser(null));
    
  }
  const tsx = <Provider store={store}>
      <App />
  </Provider>
  ReactDOM.render(tsx, document.getElementById('root'));
  defineCustomElements(window);
});


document.addEventListener('touchmove', function (event:any) {
  if (event.scale !== 1) { event.preventDefault(); }
}, { passive: false });

var lastTouchEnd = 0;
document.addEventListener('touchend', function (event) {
  var now = (new Date()).getTime();
  if (now - lastTouchEnd <= 300) {
    event.preventDefault();
  }
  lastTouchEnd = now;
}, false);


checkOfflineUploads()

window.addEventListener('online', () => store.dispatch(SetIsOnlineAction(true)));
window.addEventListener('offline', () => store.dispatch(SetIsOnlineAction(false)));


