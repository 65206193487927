import React, { useState } from 'react';


const OsteoarthritisPage: React.FC = () => {
  
    const [ showReferences, setShowReferences ] = useState<boolean>(false);

    return (
    <div className='diagnosis-treatment-doc'>
        <h2>Osteoarthritis - Recommendations</h2>
        
        <h3>Diagnostic confirmation</h3>
        <ul>
            <li>Plain X-rays of the hands, to confirm typical radiographic changes of osteoarthritis¹</li>
            <li>Joint-space narrowing</li>
            <li>Osteophyte formation</li>
            <li>Subchondral sclerosis/cysts/erosions</li>
        </ul>


<h3>Treatment options </h3>

<ul>
    <li>Education and training in ergonomic principles, activity pacing and use of assistive devices²</li>
    <li>Simple analgesia: paracetamol²⁻⁴, topical/oral non-steroidal anti-inflammatory medication²⁻⁹</li>
    <li>Cartilage supplements: glucosamine sulfate, chondroitin sulfate²,¹⁰⁻¹³</li>
    <li>Exercises to improve muscle strength, reduce pain²˒ ¹⁴˒¹⁵</li>
    <li>Occupational therapy assessment for splinting (basal thumb involvement)¹⁶</li>
    <li>Other considerations can include weight loss¹⁷, intra-articular steroid injection²,¹⁸⁻²⁰ and surgical assessment in severe cases²˒⁴</li>
</ul>

<h3 onClick={()=>setShowReferences(!showReferences)}>References (click to expand)</h3>
        { showReferences && <div>
            
            <p>1. Zhang W, Doherty M, Leeb BF, Alekseeva L, Arden NK, Bijlsma JW, Dincer F, Dziedzic K, Hauselmann HJ, Kaklamanis P, Kloppenburg M, Lohmander LS, Maheu E, Martin-Mola E, Pavelka K, Punzi L, Reiter S, Smolen J, Verbruggen G, Watt I, Zimmermann-Gorska I, ESCISIT. EULAR evidence-based recommendations for the diagnosis of hand osteoarthritis: report of a task force of ESCISIT. Ann Rheum Dis 2009. 68(1):8-17.</p>
            <p>2. Kloppenburg M, Kroon FP, Blanco FJ, Doherty M, Dziedzic KS, Greibrokk E, Haugen IK, Herrero-Beaumont G, Jonsson H, Kjeken I, Maheu E, Ramonda R, Ritt MJ, Smeets W, Smolen JS, Stamm TA, Szekanecz Z, Wittoek R, Carmona L. 2018 update of the EULAR recommendations for the management of hand osteoarthritis. Ann Rheum Dis 2019. 78(1):16-24.</p>
            <p>3. Towheed TE, Maxwell L, Judd MG, et al. Acetaminophen for osteoarthritis. Cochrane Database Syst Rev 2006. (1) CD004257.</p>
            <p>4. National Institute for Health & Care Excellence. Osteoarthritis: care and management in adults. NICE 2014. https://www.nice.org.uk/guidance/cg177</p>
            <p>5. Zeng C, Wei J, Persson MSM, Sarmanova A, Doherty M, Xie D, Wang Y, Li X, Li J, Long H, Lei G, Zhang W. Relative efficacy and safety of topical non-steroidal anti-inflammatory drugs for osteoarthritis: a systematic review and network meta-analysis of randomised controlled trials and observational studies. Br J Sports Med 2018. 52(10):642–650.</p>
            <p>6. Puljak L, Marin A, Vrdoljak D, Markotic F, Utrobicic A, Tugwell P. Celecoxib for osteoarthritis. Cochrane Database Syst Rev 2017. 5:CD009865.</p>
            <p>7. Sanders, D. et al. Pharmacologic modulation of hand pain in osteoarthritis: a double-blind placebo-controlled functional magnetic resonance imaging study using naproxen. Arthritis Rheumatol 2015. 67:741–751.</p>
            <p>8. Dreiser RL, Gersberg M, Thomas F, Courcier S. Ibuprofen 800 mg in the treatment of arthrosis of the fingers or rhizarthrosis. Rev Rhum Ed Fr 1993 60:836–841.</p>
            <p>9. Grifka JK, Zacher J, Brown JP, Seriolo B, Lee A, Moore A, Gimona A. Efficacy and tolerability of lumiracoxib versus placebo in patients with osteoarthritis of the hand. Clin Exp Rheumatol 2004. 22:589–596.</p>
            <p>10. Tenti S, Giordano N, Mondanelli N, Giannotti S, Maheu E, Fioravanti A. A retrospective observational study of glucosamine sulfate in addition to conventional therapy in hand osteoarthritis patients compared to conventional treatment alone. Aging Clin Exp Res 2020. 32(6):1161-1172.</p>
            <p>11. Singh JA, Noorbaloochi S, MacDonald R, Maxwell LJ. Chondroitin for osteoarthritis. Cochrane Database Syst Rev 2015. 1:CD005614.</p>
            <p>12. Scarpellini M, Lurati A, Vignati G, Marrazza MG, Telese F, Re K, Bellistri A. Biomarkers, type II collagen, glucosamine and chondroitin sulfate in osteoarthritis follow-up: the Magenta osteoarthritis study. J OrthopTraumatol 2008. 9(2):81-87.</p>
            <p>13. Erickson, JM, Messer, T.M. Glucosamine and Chondroitin Sulfate Treatment of Hand Osteoarthritis. J Hand Surg Am 2013. 38(8):1638-1640.</p>
            <p>14. Magni NE, McNair PJ, Rice DA. The effects of resistance training on muscle strength, joint pain, and hand function in individuals with hand osteoarthritis: a systematic review and meta-analysis. Arthritis Res Ther 2017. 19:131</p>
            <p>15. Osteras N, Kjeken I, Smedslund G, Moe RH, Slatkowsky-Christensen B, Uhlig T, Hagen KB. Exercise for hand osteoarthritis. Cochrane Database Syst Rev 2017. 1:CD010388.</p>
            <p>16. Egan M, Brosseau L. Splinting for osteoarthritis of the carpometacarpal joint: a review of the evidence. Am J Occup Ther 2007. 61:70-78.</p>
            <p>17. Magnusson K, Slatkowsky-Christensen B, van der Heijde D, Kvien TK, Hagen KB, Haugen IK. Body mass index and progressive hand osteoarthritis: data from the Oslo hand osteoarthritis cohort. Scand J Rheumatol 2015. 44:331–336.</p>
            <p>18. Trellu S, Dadoun S, Berenbaum F, Fautrel B, Gossec, L. Intra-articular injections in thumb osteoarthritis: a systematic review and meta-analysis of randomized controlled trials. Jt Bone Spine 2015. 82:315–319.</p>
            <p>19. Spolidoro Paschoal NO, Natour J, Machado FS, de Oliveira HA, Furtado RN. Effectiveness of triamcinolone hexacetonide intraarticular injection in interphalangeal joints: a 12-week randomized controlled trial in patients with hand osteoarthritis. J Rheumatol 2015. 42:1869–1877.</p>
            <p>20. Favero M, Hoxha A, Frallonardo P, Ortolan A, Lorenzin M, Felicetti M, Belluzzi E, Doria A, Ramonda R. Efficacy and Safety of Ultrasound-Guided Intra-articular Glucocorticoid Injection in Erosive Hand Osteoarthritis. Pain Med 2021. 22(5):1229-1232.</p>


            </div>}

      </div>
  );
};

export default OsteoarthritisPage;
