import api from "../data/api";
import { IUser } from "../data/IUser";

export const SetUser = (user:IUser | null) => {
    return {
        type: 'SET_USER',
        value: user
    }
}

export const startLogout = () =>{
    return () =>{
        return api.logout();
    };
}