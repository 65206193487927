import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IGlobalState } from '../../store/IGlobalState';
// import LoadingComponent from '../LoadingComponent';
import api from '../../data/api';
import { EditSurvey, StartSaveAction } from '../../actions/SurveyActions';
import adminApi from '../../data/adminApi';
import useAsyncEffect from 'use-async-effect';
import { isUploadingImages, to } from '../../data/util';
import { IonButton, IonContent, IonModal } from '@ionic/react';
import OsteoarthritisPage from './Treatment/OsteoarthritisPage';
import GoutPage from './Treatment/GoutPage';
import Card from '../common/Card';


const CalculatingDiagnosisStep: React.FC = (props:any) => {  
  const survey = useSelector((state:IGlobalState)=>state.survey);  
  const [ calculating, setCalculating ] = useState<boolean>(!survey.diagnosis);
  const [ uploading, setUploading ] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [ error, setError ] = useState<string>('');
  const [ modalOpen, setModalOpen ] = useState<boolean>(false);

  const submit = async () => {
    
    setCalculating(true);
    setError('')
    console.log('does not have diagnosis...calculating')      
    while((await isUploadingImages(survey.id))){
      console.log('images are still uploading')
      setUploading(true);
      await new Promise(r => setTimeout(r, 2000));
    }

    const [err, result ] = await to (adminApi.getDiagnosis(survey.id));         
    
    if(err){
      console.dir(err);
      const message =  err?.response?.data?.message || `An error occurred - please try again`;         
     
      setError(message)
    }else{
      console.log('CalculatingDiagnosisStep', result)   
      const diagnosis:string = result.prediction.join(',');
      await dispatch(EditSurvey({ diagnosis }))
      await dispatch(StartSaveAction())      
      await api.setSurveyReadonly(survey.id);   
    }
     
    setCalculating(false)
  
  }

  useAsyncEffect(async ()=>{
    
    if(calculating){
      await submit();
    }
    
  }, 
  // eslint-disable-next-line
  [  ])
  

  const diagnosis = survey.diagnosis?.toLowerCase();
  const referralRequired = ![ "gout", "osteoarthritis"].includes(diagnosis);

  return <Card>
  <div className='step-option-question__title-container'>      
      <span className='step-option-question__header'>Screening result:</span>
    </div>
  

  {  calculating && <>
    {/* <LoadingComponent /> */}
    <img src="/assets/images/hand_animation.gif" className="hand-animation" alt="hand_animation" />
  <p className="text-center">
    { uploading && <span>Images are uploading...</span> }
    { !uploading && <span>Calculating please wait...</span> }

  </p>
  </>}

  { error && <div>
    <div className="alert alert-danger">{error}</div>
    <IonButton onClick={submit}>Retry</IonButton>
    </div>}
  
  { !calculating && !error && <>
    
    <p style={{fontSize:'24px', fontWeight:'bold', padding:'15px', backgroundColor:'lightgray', margin:'10px 0'}}><span >{survey.diagnosis}</span></p>
    
    { referralRequired && <p>For cases of suspected inflammatory arthritis, prompt referral to a Specialist Rheumatologist for diagnostic confirmation and disease-modifying treatment is recommended</p> }
    { !referralRequired && <p>Please <a href="/" onClick={(e)=> { e.preventDefault(); setModalOpen(!modalOpen)}}>click here</a> for diagnostic confirmation and treatment recommendations</p> }
    
    
    <p style={{marginTop:'50px'}}>Return to <Link to="/surveys">existing surveys</Link></p>
  </>}


  <IonModal
    isOpen={modalOpen}
    swipeToClose={true}
    
    presentingElement={undefined}
  >
    <IonContent>
      <div className="survey-modal-close-container">
        <IonButton onClick={()=>setModalOpen(false)}>Close</IonButton></div>
      { diagnosis === "gout" && <GoutPage/> }
      { diagnosis === "osteoarthritis" && <OsteoarthritisPage/> }
      
    </IonContent>
  </IonModal>
</Card>;
};

export default CalculatingDiagnosisStep;
