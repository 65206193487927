import { getItem } from "./storage";
import { UploadRegistry } from "./UploadRegistry";
import { isPlatform } from "@ionic/react";
import { localImageStoragePath, uploadBase64 } from "./util";
import * as storage from './storage';
import { FilesystemDirectory  } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
import log from "../util/log";

const { Filesystem } = Plugins;

const getBase64 = async (image: string): Promise<{success:boolean, base64:string}> => {
    if (isPlatform('hybrid')) {
        let ret = await Filesystem.stat({
            path: image,
            directory: FilesystemDirectory.Data
        });
        if (ret.size){
            const file = await Filesystem.readFile({
                path: ret.uri
            });
            return { success : true, base64: file.data};
        }
        return { success : false, base64: ''};

        
    } else {
        return { success : true, base64: await storage.getItem<string>(localImageStoragePath(image))}
    }
}

export default async () => {
    const registry = await getItem<UploadRegistry>("UploadRegistry", UploadRegistry as any);
    log.info(`UploadRegistry count: ${registry.items.length}`)
    registry.items.forEach(async (item) => {

        log.info(`image ${item.image} is still in storage. uploading...`)          
        try {
            const result = await getBase64(item.image);
        if(result.success){
            uploadBase64({ data: result.base64}, item)
        }else{
            log.error(`failed to retrieve base64 for image ${item.image}`)
        }
        } catch (error) {
            log.error(error as any);
            //await deleteImageFromUploadRegistry(item.image)
        }
        
    });
}