import React, { useState } from 'react';


const GoutPage: React.FC = () => {
  
    const [ showReferences, setShowReferences ] = useState<boolean>(false);

    return (
    <div className='diagnosis-treatment-doc'>
        <h2>Gout</h2>
        {/* 
        ¹²³⁴⁵⁶⁷⁸⁹⁰ 
        
        */}

        <h3>Diagnostic confirmation¹</h3>
        <ul>
            <li>Microscopy to assess for the presence of monosodium urate crystals (MSU) in synovial fluid or a tophus aspirate is recommended in patients with suspected gout, as it allows for a definitive diagnosis</li>
            <li>Clinical suspicion is suggested by: monoarticular involvement of a foot (especially the first MTP) or ankle joint; previous similar acute arthritis episodes; rapid onset of severe pain and swelling (peak within 24 hours), male gender, hyperuricaemia</li>
            <li>Plain radiographs can show evidence of MSU crystal deposition (bony erosion, soft tissue swelling). Ultrasound may also be able to identify tophi not evident on clinical examination, and/or a double-contour sign at cartilage surfaces.</li>
            <li>Risk factors for hyperuricaemia should be assessed in those with suspected gout, including chronic renal disease, obesity, medications (diuretics, low-dose aspirin, cyclosporine, tacrolimus), and consumption of excess alcohol, non-diet sodas, meat and shellfish.</li>
            <li>Systematic assessment for the presence of associated comorbidities in people with gout is recommended, including obesity, renal impairment, hypertension, ischaemic heart disease, heart failure, diabetes and dyslipidaemia.</li>
        </ul>
        

        <h3>Treatment recommendations² </h3>
<p>Each patient should be informed about the pathophysiology of the disease, the existence of effective treatments, associated comorbidities and the principles of managing acute attacks and eliminating urate crystals through lifelong lowering of SUA level below a target level. </p>
<p>Lifestyle advice: weight loss if appropriate, avoidance of alcohol and sugar-sweetened drinks, excessive intake of meat and seafood. </p>
<p>Comorbidity screening: renal impairment, coronary heart disease, heart failure, stroke, peripheral arterial disease, obesity, hyperlipidaemia, hypertension, diabetes and smoking.</p>
<p>Acute flares of gout should be treated as soon as possible. Patients should be educated to self-medicate at the first warning symptoms. The choice of medication should be based on the presence of contraindications, the patient’s previous experience with treatments, time of initiation after flare onset and the number and type of joint(s) involved. </p>
<p>Acute treatment</p>
<p>Colchicine (within 12 hours of flare onset) at a loading dose of 1 mg followed 1 hour later by 0.5 mg on day 1 and/or an NSAID (plus proton pump inhibitors if appropriate), oral corticosteroid (30–35 mg/day of equivalent prednisolone for 3–5 days) or articular aspiration and injection of corticosteroids. </p>
<p>Colchicine and NSAIDs should be avoided in patients with severe renal impairment. Colchicine should not be given to patients receiving strong P-glycoprotein and/or CYP3A4 inhibitors such as cyclosporin or clarithromycin. </p>
<p>In patients with frequent flares and contraindications to colchicine, NSAIDs and corticosteroid (oral and injectable), IL-1 blockers should be considered for treating flares.</p>

<p>Long-term treatment</p>

<p>Urate-lowering therapy (ULT) should be considered and discussed with every patient with a definite diagnosis of gout from the first presentation. ULT is indicated in all patients with recurrent flares, tophi, urate arthropathy and/or renal stones. </p>
<p>Initiation of ULT is recommended close to the time of first diagnosis in patients presenting at a young age (&lt;40 years) or with a very high serum uric acid (SUA) level (8.0 mg/dL; 0.48 mmol/L) and/or comorbidities (renal impairment, hypertension, ischaemic heart disease, heart failure). </p>
<p>For patients on ULT, SUA level should be monitored and maintained to &lt;6 mg/dL (0.36 mmol/L). A lower SUA target (&lt;5 mg/dL; 0.30 mmol/L) to facilitate faster dissolution of crystals is recommended for patients with severe gout (tophi, chronic arthropathy, frequent attacks) until total crystal dissolution and resolution of gout. </p>
<p>All ULTs should be started at a low dose and then titrated upwards until the SUA target is reached. SUA &lt;6 mg/dL (0.36 mmol/L) should be maintained lifelong.</p>
<p>In patients with normal kidney function, allopurinol is recommended for first-line ULT, starting at a low dose (100 mg/day) and increasing by 100 mg increments every 2–4 weeks if required, to reach the SUA target. If the SUA target cannot be reached by an appropriate dose of allopurinol, allopurinol should be switched to febuxostat or a uricosuric or combined with a uricosuric. Febuxostat or a uricosuric are also indicated if allopurinol cannot be tolerated.</p>
<p>In patients with crystal-proven, severe debilitating chronic tophaceous gout and poor quality of life, in whom the SUA target cannot be reached with any other available drug at the maximal dosage (including combinations), pegloticase can be considered.</p>
<p>When gout occurs in a patient receiving loop or thiazide diuretics, substitute the diuretic if possible; for hypertension consider losartan or calcium channel blockers; for hyperlipidaemia, consider a statin or fenofibrate.</p>


<h3 onClick={()=>setShowReferences(!showReferences)}>References (click to expand)</h3>
        { showReferences && <div>
            
            <p>1.	Richette P, Doherty M, Pascual E, Barskova V, Becce F, Castaneda J, Coyfish M, Guillo S, Jansen T, Janssens H, Liote F, Mallen CD, Nuki G, Perez-Ruiz F, Pimentao J, Punzi L, Pywell A, So AK, Tausche AK, Uhlig T, Zavada J, Zhang W, Tubach F, Bardin T. 2018 updated European League Against Rheumatism evidence-based recommendations for the diagnosis of gout. Ann Rheum Dis 2020 79(1):31-38.</p>
            <p>2.	Richette P, Doherty M, Pascual E, Barskova V, Becce F, Castaneda-Sanabria J, Coyfish M, Guillo S, Jansen TL, Janssens H, Liote F, Mallen C, Nuki G, Perez-Ruiz F, Pimentao J, Punzi L, Pywell T, So A, Tausche AK, Uhlig T, Zavada J, Zhang W, Tubach F, Bardin T. 2016 updated EULAR evidence-based recommendations for the management of gout. Ann Rheum Dis 2017 76(1):29-42.</p>
           
            </div>}

      </div>
  );
};

export default GoutPage;
