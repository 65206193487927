import React, { useState } from 'react';
import { IonButton, IonImg } from '@ionic/react';
import api from '../data/api';
import { FaSpinner } from 'react-icons/fa';
import log from '../util/log';
import environment from '../util/environment'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface IProps {
  
}

const Login: React.FC<IProps> = () => {

    const [ isLoggingIn, setIsLoggingIn ] = useState(false);
    const lastLoginSuccessEmail = localStorage.getItem('lastLoginSuccessEmail')  
    const [ username, setUsername ] = useState(lastLoginSuccessEmail || '');
    const [ password, setPassword ] = useState('');
    const [ errorMessage, setErrorMessage ] = useState<string>();
    const [showPassword, setShowPassword] = useState(false);
    
    const login = () => {
      setErrorMessage('');
      setIsLoggingIn(true)
      try {
        api.signIn(username, password)
        .then((isAuthenticated)=>{
          const result = isAuthenticated ? '' : 'Invalid username/password';
          
          if(result){
            setErrorMessage(result);
            setIsLoggingIn(false)
          }else{
            log.info('authenticated...onAuthStateChanged should be triggered which will redirect to /surveys')
            //props.setAuthenticated();
          }
        })
        
      } catch (e) {
        setErrorMessage(JSON.stringify(e));
      }
    }
    const version = environment.version;
    
    return <div>
      
      <div className="login-page">
            <div>
            <div className="login-logo-container">
              
              <IonImg src="/assets/icon/icon.png" className="login-ion-icon" />
              <h2>Login to your account</h2>
            </div>
            <div style={{padding: '0 2rem'}}>
            <FormControl variant="outlined" sx={{ width: '100%' }}>
                    <InputLabel htmlFor="outlined-adornment-password">Email Address</InputLabel>
                    <OutlinedInput
                      
                      type="email"
                      value={username}
                      fullWidth
                      onChange={e => setUsername(e.target.value)}                      
                      label="Email Address"
                    />
                  </FormControl>

               <FormControl variant="outlined" sx={{ width: '100%', margin: '1rem 0' }}>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput                      
                      type={showPassword ? 'text' : 'password'}                      
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>

                  {errorMessage && <div className="login-error">{errorMessage}</div>}
            </div>
           

            <IonButton className="login-page__button" color="tertiary" onClick={() => { login() }}><span>Login </span>
            {isLoggingIn && <FaSpinner className="fa-spin" size={16} style={{marginLeft:'10px'}} />}
            </IonButton>
            
            
            </div>

            
          </div>
      
      <div className="login-footer">HandScan v<span>{version}</span></div>
    </div>
};

export default Login;
