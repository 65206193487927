export enum StepQuestionType {
    DurationOfSymptoms = "DurationOfSymptoms",
    WorseTimeOfDay = "WorseTimeOfDay",
    MorningStiffness = "MorningStiffness",
    ResponseToActivity = "ResponseToActivity",
    ResponseToRest = "ResponseToRest",
    PersonalHistory = "PersonalHistory",
    FamilyHistory = "FamilyHistory",
    AreasOfPain = "AreasOfPain",
    AntiInflammatories = "AntiInflammatories",
    WristIrritability = "WristIrritability",
    DoctorDiagnosis = "DoctorDiagnosis",
}

export class StepQuestion {
    constructor(public type:StepQuestionType, public title:string, public options:string[], 
        public num:number,
        public multi:boolean = false, public additionalInfo:string = ''){

    }
}

//not applicable

const arr:StepQuestion[] = [
    new StepQuestion(StepQuestionType.DurationOfSymptoms, "Duration of symptoms", [ "< 3 months", "3-12 months", "1-2 years", "> 2 years" ], 1),
    new StepQuestion(StepQuestionType.WorseTimeOfDay, "Worse time of day", [ "Morning", "Evening", "Not Applicable" ], 2),
    new StepQuestion(StepQuestionType.MorningStiffness, "Morning stiffness", [ "Not Applicable", "< 10 min", "10-30 min", "> 30 min" ], 3),
    new StepQuestion(StepQuestionType.ResponseToActivity, "Response to activity", [ "Better", "Worse", "No difference" ], 4),
    new StepQuestion(StepQuestionType.ResponseToRest, "Response to rest", [ "Better", "Worse", "No difference" ], 5),
    new StepQuestion(StepQuestionType.PersonalHistory, "Personal history", [ "Psoriasis", "Gout", "Nil" ], 6, true),
    new StepQuestion(StepQuestionType.FamilyHistory, "Family history", [ "Osteoarthritis", "Rheumatoid Arthritis", "Psoriasis", "Gout", "Nil" ], 7, true, "*refers to first-degree relatives: parents, siblings, children"),
    new StepQuestion(StepQuestionType.AreasOfPain, "Areas of pain", [ "Fingers", "Thumb", "Wrist", "Foot" ], 8, true),
    new StepQuestion(StepQuestionType.AntiInflammatories, "Anti-inflammatories", [ "No response", "Mild benefit", "Moderate benefit", "Significant benefit", "Not Applicable" ], 9),
]

export default arr;