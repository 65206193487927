import React from 'react';
import {   IonItem, IonItemSliding, IonItemOptions, IonItemOption   } from '@ionic/react';
import { IUser } from '../../data/IUser';

interface IProps {
  user: IUser;
  onDelete(user:IUser):void
}

const UserListItem: React.FC<IProps> = (props:IProps) => {
  const user = props.user;
  //const date = moment(survey.updated);
  
  return (<IonItemSliding>

    <IonItem routerLink={`/users/${user.uid}`}>
      <div className="user-list-item">
        <span>{user.username}</span>
      </div>


    </IonItem>

    <IonItemOptions side="end">
      <IonItemOption color="danger" onClick={() => props.onDelete(user)}>Delete</IonItemOption>
    </IonItemOptions>
  </IonItemSliding>
    

  );
};

export default UserListItem;
