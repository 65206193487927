import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import userReducer from '../reducers/user';
import surveyReducer from '../reducers/survey';
import surveysReducer from '../reducers/surveys';
import versionReducer from '../reducers/version';
import serviceWorkerReducer from '../reducers/serviceWorker';
import webPathReducer from '../reducers/webPathReducer';
import isOnlineReducer from '../reducers/isOnlineReducer';
import isCameraActiveReducer from '../reducers/isCameraActiveReducer';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    combineReducers({
        user: userReducer, 
        survey: surveyReducer, 
        surveys: surveysReducer, 
        remoteVersion: versionReducer,
        serviceWorkerState: serviceWorkerReducer,
        webPaths: webPathReducer,
        isOnline: isOnlineReducer,
        isCameraActive: isCameraActiveReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))        
);

export default store

