import React from 'react';
import Card from '../components/common/Card';


const Page:React.FC  = () => {
  return <div style={{padding: '7px 15px 15px'}}>
    <Card>
      <div style={{height:340}}>
      <video className='photo-guide__video'
        src="/assets/images/demo.mp4" controls autoPlay playsInline
      />
      </div>
     
      
    </Card>
    <p style={{margin: 0}}>How to take acceptable photos</p>

    <Card margin='0'>
      {<div className="photo-guide__sample-photos">
        <img src="/assets/images/correct.jpg" alt=""/>
        <img src="/assets/images/incorrect.jpg" alt=""/>

      </div>}
    </Card>
  </div>
}

export default Page;