import { ImageWebPath } from "../data/ImageWebPath";

export default (state:ImageWebPath[] = [], action:{type:string, value:ImageWebPath}) => {
  
  if(action.type === 'ADD_WEB_PATH'){
    if(state.filter(x=>x.image === action.value.image).length === 0){
      return [ ...state, action.value ]
    }
  }
  else if(action.type === 'DELETE_WEB_PATH'){
    if(state.filter(x=>x.image === action.value.image).length > 0){
      return state.filter(x=>x.image !== action.value.image)
    }
  }
  return state;
}

