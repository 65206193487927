import React, { ReactNode } from 'react';
import MaterialCard from '@mui/material/Card';
import { CardContent } from '@mui/material';

interface IProps {
    children: ReactNode
    margin?:string
}
const Card: React.FC<IProps> = (props:IProps) => {
    const style = { 
        borderRadius: '24px',
        boxShadow: '0px 4px 4px rgba(47, 128, 237, 0.2)',
        margin: props.margin || '0.5rem 0',
        '& .MuiCardContent-root': { paddingBottom: '16px' }
    }
    return <MaterialCard sx={style} >
        <CardContent>
            {props.children}
        </CardContent>
        
    </MaterialCard> 
}

export default Card;