import { IUser } from "../data/IUser";

const defaultState = null;

export default (state:IUser|null = defaultState, action:{type:string, value:IUser|null}) => {
  
  switch(action.type){    
    case 'SET_USER':       
    return action.value;
    
    default:
      return state;
  }
}

