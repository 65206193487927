import React, { useState } from 'react';
import { IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import { IUser } from '../../data/IUser';
import { Survey } from '../../data/Survey';
import SurveyListItem from '../../components/SurveyListItem';
import { IGlobalState } from '../../store/IGlobalState';
import { connect } from 'react-redux';
import api from '../../data/api';

interface IProps {    
    user:IUser
    surveys:Survey[]
 }

const EditUserComponent: React.FC<IProps> = (props:IProps) => {
  const [ deleting, setDeleting ] = useState<boolean>(false);
  const downloadLog = async ()=>{
    const logs = await api.getLogs(props.user.username);
    console.log('logs', logs)
  }
  const deleteArchivedSurveys = async ()=>{
    setDeleting(true)
    let archived = await api.getArchived(props.user.username);    
    for(let survey of archived){
      console.log(`deleting survey ${survey.id} by ${survey.userEmail}`);
      await api.deleteSurvey(survey);
    }
    setDeleting(false)
    alert(`deleted ${archived.length}`)
    
  }

  return (
      <div>
          <IonList>
              <IonItem>
                  <IonLabel>Username</IonLabel>
                  <IonInput readonly={true} autocomplete="off" name="username" type="email" value={props.user.username} />
              </IonItem>
              <IonItem>
                  <IonButton onClick={downloadLog}>Download Log</IonButton>
                  
              </IonItem>
              <IonItem>
                  <IonButton onClick={deleteArchivedSurveys} disabled={deleting}>{deleting ? "Deleting" : "Delete Archived Surveys" }</IonButton>
                  
              </IonItem>
              
          </IonList>

          <IonList>

              {props.surveys.map((survey: Survey, index: number) => <SurveyListItem key={index} survey={survey} uid={props.user.uid} onDelete={()=>{}} />)}

              {props.surveys.length === 0 && <IonItem><p>No Surveys for user. </p></IonItem>}
          </IonList>

          {/* <IonButton onClick={save}>Save</IonButton> */}

      </div>
  );
};

const mapStateToProps = (state:IGlobalState, ownProps:{user:IUser}) =>{
    return {
      surveys: state.surveys.filter(x=>x.uid===ownProps.user.uid),      
    }
  }
export default connect(mapStateToProps)(EditUserComponent);
  

  