import { set, get, del  } from 'idb-keyval';

export type StorageKeys = "UploadRegistry"

//const storage = localStorage;

export function removeItem(key:string) : void {
    // storage.removeItem(key);    
    del(key);
}



export async function getItem<T>(key:StorageKeys|string, ctor?: any) : Promise<T> {
    //let json = storage.getItem(key);    
    
    const data = await get(key)
    if(data){
        return data as T;
    }
    
    return (ctor === undefined ? '' : new ctor()) as T
}

export function setItem<T>(key:StorageKeys|string, data:T) : Promise<void> {
    //storage.setItem(key, JSON.stringify(data))
    return set(key, data)        
}