import React, { useState } from 'react';
import { IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import { IUser } from '../../data/IUser';


interface IProps {
    onSave(user:IUser, password:string):void;
    saving:boolean
 }

const NewUserComponent: React.FC<IProps> = (props:any) => {

    const [ displayName, setDisplayName ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');

    
    const save = (e:any)=>{
        e.preventDefault()
        let user:IUser = {            
            uid:'',
            username,
            displayName
        }
        props.onSave(user, password);        
    }
    

  return (
      <form onSubmit={save}>
          <IonList>
              <IonItem>
                  <IonLabel>Email</IonLabel>
                  <IonInput disabled={props.saving} required={true} autocomplete="off" type="email" value={username} onIonChange={(e) => setUsername((e.target as HTMLInputElement).value)} />
              </IonItem>
              <IonItem>
                  <IonLabel>Password</IonLabel>
                  <IonInput disabled={props.saving} required={true} autocomplete="off" type="text" value={password} onIonChange={(e) => setPassword((e.target as HTMLInputElement).value)} />
              </IonItem>
              <IonItem>
                  <IonLabel>Display Name</IonLabel>
                  <IonInput disabled={props.saving} required={true} autocomplete="off" type="text" value={displayName} onIonChange={(e) => setDisplayName((e.target as HTMLInputElement).value)} />
              </IonItem>
          </IonList>


          <IonButton disabled={props.saving} type="submit">Save</IonButton>

      </form>
  );
};


  
  export default NewUserComponent;

  