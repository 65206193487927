import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { IonList, IonItem, IonButton } from '@ionic/react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IUser } from '../../data/IUser';
import UserListItem from './UserListItem';
import LoadingComponent from '../../components/LoadingComponent';
import adminApi from '../../data/adminApi';
import DeleteUserConfirmationAlert from './DeleteUserConfirmationAlert';


interface IProps extends RouteComponentProps<{ name: string; }>{
    
}
const UsersListComponent: React.FC<IProps> = (props: IProps) => {

    const [ users, setUsers ] = useState<IUser[]|undefined>(undefined);
    const [ showAlert, setShowAlert ] = useState<boolean>(false);
    const [ pendingDeleteUser, setDeleteUser ] = useState<IUser>();
    
    const onDeleteConfirmed = async () => {        
        await adminApi.deleteUser(pendingDeleteUser!.uid)
        setUsers(users?.filter(x=>x.uid!==pendingDeleteUser!.uid));
    }

    

    useAsyncEffect(async (isMounted)=>{
        const users = await adminApi.getUsers();
        if(isMounted()){
            setUsers(users);
        }       
    }, [])

    const UsersList = () => {
        return <>
            <IonButton routerLink="/users/new">Add User</IonButton>

            <IonList>

                {users!.map((user: IUser, index: number) => <UserListItem key={index} user={user} onDelete={(user)=>{
                    setShowAlert(true)
                    setDeleteUser(user)
                }} />)}

                {users!.length === 0 && <IonItem><p>No Users found. <Link to="/new-survey">Create New</Link></p></IonItem>}
            </IonList>
        </>

    }
    return (
        < >
            
            {!users && <LoadingComponent />}
            {users && <UsersList />} 

        { pendingDeleteUser && <DeleteUserConfirmationAlert user={pendingDeleteUser} onDeleteConfirmed={onDeleteConfirmed} showAlert={showAlert} setShowAlert={setShowAlert}    />}
        </>

    );
};


export default UsersListComponent
