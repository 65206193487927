import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import 'firebase/auth';        // for authentication
import 'firebase/firestore';

// import log from '../util/log';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCQcsgcrK9jIiCRp4r-XjnLRPVG7WM5CsQ",
  authDomain: "algebraic-inn-332200.firebaseapp.com",
  projectId: "algebraic-inn-332200",
  storageBucket: "algebraic-inn-332200.appspot.com",
  messagingSenderId: "336772611528",
  appId: "1:336772611528:web:4d9f8bca61018ff88954fd",
  measurementId: "G-0WN15L6BJ9",
  databaseURL: "https://algebraic-inn-332200.firebaseio.com",
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.firestore()
//.enablePersistence().catch(log.error);
// Subsequent queries will use persistence, if it was enabled successfully

const database = firebase.firestore();

const emailAuthProvider = new firebase.auth.EmailAuthProvider();

export { firebase, emailAuthProvider, database as default };


