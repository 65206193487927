import database, { firebase } from '../data/firebase';
import { Survey } from './Survey';
import { to, deleteImageFromUploadRegistry } from './util';
import { setSurveys } from '../actions/SurveysActions';
import * as storage from '../data/storage';
import environment from '../util/environment';
import { SetVersion } from '../actions/VersionAction';
import { getItem, setItem } from './storage';
import { UploadRegistry, UploadItem } from './UploadRegistry';
import adminApi from './adminApi';
import log from '../util/log';
import { LogDocument } from '../util/LogDocument';

class Api {

    
  unsubscribeToSurveys!:()=>void
  unsubscribeToVersion!:()=>void

  async deleteFolder(path:string) : Promise<void> {
    let ref = firebase.storage().ref(path);
    const dir = await ref.listAll();
    dir.items.forEach(async (fileRef) => {
      var dirRef = firebase.storage().ref(fileRef.fullPath);
      const url = await dirRef.getDownloadURL();
      
      var imgRef = firebase.storage().refFromURL(url);
      await imgRef.delete()    
    })
  }
  async getArchived(email:string) : Promise<Survey[]> {
    return new Promise((resolve, reject)=> {
      
      database.collection("surveys").where("archived", "==", 1)
      .where("userEmail", "==", email)
      .get()
      .then((querySnapshot) => {
        let arr:Survey[] = []  
        querySnapshot.forEach((doc) => {
              
            //log.info(`archived doc Id ${doc.id}`);
              let survey = doc.data() as Survey;
              arr.push(survey);
              // if(doc.id === ""){              
              //   this.deleteSurvey(survey)                
              // }
          });
          resolve(arr)
      })
      .catch(function(error) {
        reject("Error getting documents: " + error);
      });
    })
    
  }



  async subscribeToSurveys(store: any, hasReadAllSurveysRole:boolean): Promise<void> {
    return new Promise(async (resolve,reject)=>{
      
    const user = firebase.auth().currentUser!;
    
    let query:any = database.collection("surveys").where("archived", "==", 0)
    if(!hasReadAllSurveysRole){
      query = query.where("uid", "==", user.uid);
    }
    
    this.unsubscribeToSurveys = query.orderBy("updated", "desc")
      .onSnapshot({ includeMetadataChanges: true }, (snapshot:any) => {        
        const updates: Survey[] = [];
        const removed: string[] = [];
        snapshot.docChanges().forEach((change:any) => {          
          
          if (change.type === "removed") {
            removed.push(change.doc.id);
          }else{            
            let survey = change.doc.data() as Survey;
            survey.id = change.doc.id;
            updates.push(survey)
          }
          
          
        });
        
        store.dispatch(setSurveys(updates, removed));
        resolve();
      });
  
    })
  }

  async checkVersion(store: any): Promise<void> {
    
    let [err, version] = await to(adminApi.getVersion());   
    
    if (err) {
      version = await storage.getItem('version') || ''
    }else{
      await storage.setItem('version', version)
    }
    
    store.dispatch(SetVersion(version));
    if (environment.version !== version) {
      log.info(`version change detected from ${environment.version} to ${version}.`);
      

    } else {
      log.info('version matches')
      localStorage.setItem('reloadCount', '0')
    }
  }

  async deleteSurvey(survey:Survey) : Promise<void> {
     
    const [err, ] = await to(this.deleteFolder(`surveyimages/${survey.uid}/${survey.id}`));
    if(err){
      log.error(`error deleting`, err)
    }

    await database.collection("surveys").doc(survey.id).delete();
    
  }

  async getLogs(email:string) : Promise<LogDocument> {
    let ref = await database.collection("logs").doc(email).get();
    return ref.data() as LogDocument
  }

  async archiveSurvey(s: Survey) : Promise<void> {
    let updates:any = {      
      archived:1,
      archivedAt : new Date().toISOString()
    }
    this.updateSurvey(s, updates, true);           
  }

  sendLog(obj: any) {
    const user = firebase.auth().currentUser!;    
    database.collection("logs").doc(user.email!).set(obj)
  }

  async saveSurvey(survey: Survey)  : Promise<void>{
    if(!survey.readonly){
      log.info(`saveSurvey called ${survey.id}`)
    }
      
    
    const ref = database.collection("surveys").doc(survey.id);
    
    let exists = false;
    try {
      const doc = await ref.get();
      exists = doc.exists;
      
    } catch (e) {
      log.info('caught exception querying doc. assuming it is not yet created. ' + e)
    }
    // log.info(`exists :${exists}`)
    if(exists){
      
      await this.updateSurvey(survey, survey);
    }else{
      survey.appVersion = environment.version;
      await ref.set(survey)
      log.info(`saved survey:`);
    }

    

  }

  async updateSurvey(survey:Survey, updates: {}, forceSave?:boolean)  : Promise<void>{
    const surveyId = survey.id;
    if(!forceSave){
      if(survey.uid !== this.getUserId()){
        log.info(`not saving survey ${surveyId} as uid not equal`)
        return;
      }
      if(survey.readonly){
        log.info(`not saving survey ${surveyId} as readonly`)
        return;
      }
    }

    //log.info(`updateSurveyInternal: surveyId:${surveyId} updates:${JSON.stringify(updates)}`) 

    await database.collection("surveys").doc(surveyId).update(updates)
    log.info(`updated surveyId ${surveyId}`);
  }

  // async saveSurveyImages(surveyId:string, images:string[]) : Promise<void> {
    
  //   return database.collection("surveys").doc(surveyId).update({
  //     images
  //   })
    
  // }

  async setSurveyReadonly(surveyId:string) : Promise<void> {
    
    database.collection("surveys").doc(surveyId).update({
      readonly: true
    })
    .then((result)=> {
      log.info(`setSurveyReadonly ${surveyId}`);
    })
    .catch(function (error) {
      log.error("Error updating document: " + error);
    });
    
  }

    async signIn(username:string, password:string) : Promise<boolean> {
      
      const [err, cred ] = await to(firebase.auth().signInWithEmailAndPassword(username, password));
        if(err){
          log.info('error signing in'+ err)
        }
        return !err && cred != null        
    }

    async logout() : Promise<void> {
      try {
        if (this.unsubscribeToSurveys) {
          this.unsubscribeToSurveys();
        }
      } catch (e) {
        log.error(e as any)
      }

      try {            
          await firebase.auth().signOut();
            // signed out
          } catch (e){
            log.error(e as any)
          } 
    }

    getUser() : { username:string } | undefined {
        const user = firebase.auth().currentUser;
        if(user){
            return { username: user.email! }
        }        
    }

    updatePassword(){
      const user = firebase.auth().currentUser;
      user!.updatePassword('fred12').then(function() {
        log.info('// Update successful.');
        
      }).catch(function(error) {
        log.error(error);    
      });
    }



  getFileUrl(userId:string,filename:string,surveyId:string) : Promise<string> {
    
    var storageRef = firebase.storage().ref(this.getFirebaseImagePath(userId, filename, surveyId));
    return storageRef.getDownloadURL();
  }

  getFirebaseImagePath(userId:string, uuid:string, surveyId:string){    
    return `surveyimages/${userId}/${surveyId}/${uuid}`;
  }

  async getSurveyImages(surveyId:string,  userId:string) : Promise<firebase.storage.ListResult> {
    var storageRef = firebase.storage().ref(`surveyimages/${userId}/${surveyId}/`);      
    return storageRef.listAll();
      
  }

  getUserId(){
    return firebase.auth().currentUser!.uid;
  }
  getUserEmail(){
    return firebase.auth().currentUser!.email!;
  }

  deleteFile(fileName:string, surveyId:string,  userId:string) : Promise<void> {
    var storageRef = firebase.storage().ref(this.getFirebaseImagePath(userId, fileName, surveyId));      
    return storageRef.delete()
  }
  
  // uploadFile2(base64Data:IBase64Data, item:UploadItem){
  //   const { image, surveyId, meta, userId }  = item;
  //   const path = this.getFirebaseImagePath(userId, image, surveyId);
  //   var ref = firebase.storage().ref(path);
  //   ref.putString(base64Data.data, "base64");
  // }

    async uploadFile(file:File, item:UploadItem) {
      const { image, surveyId, meta, userId }  = item;
      const registry = await getItem<UploadRegistry>("UploadRegistry", UploadRegistry as any);      
      if(!registry.items.find(x=>x.image === image)){
        registry.items.push( { image, surveyId, meta, userId })
        setItem("UploadRegistry", registry)
      }
      // Create a root reference
      var storageRef = firebase.storage().ref(this.getFirebaseImagePath(userId, image, surveyId));      
      
      var uploadTask = storageRef.put(file, meta);
      
      log.info('uploadTask'+ uploadTask)
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on('state_changed', function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        log.info('Upload is ' + progress + '% done');
      }, function (error) {
        // Handle unsuccessful uploads
        log.error('unsuccessful upload', error)
      }, function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
          log.info('File available at '+ downloadURL);
          await deleteImageFromUploadRegistry(image)          
          //storage.removeItem(localImageStoragePath(image))
        });
      });
    }
}

export default new Api();