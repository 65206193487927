import { firebase } from '../data/firebase';
import { IUser } from './IUser';
import axios from 'axios';
import { Role } from './Role';
import { to } from './util';
import { GcpResult } from './GcpResult';
class AdminApi {

    
    config?:{headers:any};
    
    baseUrl:string = 'https://us-central1-algebraic-inn-332200.cloudfunctions.net'
    
    async setToken() : Promise<void> {
        const user = firebase.auth().currentUser!;
        const token = await user.getIdToken();      
        this.config = {
            headers: { Authorization: `Bearer ${token}` }
        };        
    }

    async getVersion() : Promise<string> {
        
        let response = await axios.get(this.getUrl('/api/version'), this.config)        
        return Promise.resolve(response.data.version);
    }

    async getUsers() : Promise<IUser[]> {
        
        let response = await axios.get(this.getUrl('/api/users'), this.config)        
        return Promise.resolve(response.data.users.map((x:any)=> ({ uid: x.uid, username: x.email } as IUser)));
    }

    async deleteUser(uid:string) : Promise<any> {        
        return axios.delete(this.getUrl(`/api/users/${uid}`), this.config)
    }

    async getUser(uid:string) : Promise<IUser> {
        
        let response = await axios.get(this.getUrl(`/api/users/${uid}`), this.config)
        let firebaseUser = response.data.user;
        let user:IUser = {
            uid: firebaseUser.uid,
            username: firebaseUser.email
        }
        return user;
    }

    async createUser(user:IUser, password:string) : Promise<{id:string, errorMessage:string}> {
        let firebaseUser:any = {
            displayName:user.displayName,
            password,
            email: user.username, 
            roles: {}           
        }
        firebaseUser.roles[Role.CreateSurvey] = true;
        firebaseUser.roles[Role.Beta] = true;
        let result = { id: '', errorMessage: ''}
        const [err, response]  = await to(axios.post(this.getUrl(`/api/users`), firebaseUser, this.config))
        if(err){
            if(err.response?.data?.message){
                result.errorMessage = err.response.data.message
            }else{
                result.errorMessage = err + '';
            }
        }else{
            result.id = response.data.uid;
        }
        return result;
        
    }

    getUrl(path:string){
        return `${this.baseUrl}${path}`;
    }

    async getDiagnosis(id:string) : Promise<GcpResult> {
        await this.waitForCacheWithTimeout();
        await this.setToken();
        //console.log(`getDiagnosis config`, JSON.stringify(this.config));
        let response = await axios.get(this.getUrl(`/api/diagnosis/${id}`), this.config)        
        return response.data;        
    }

    async waitForCacheWithTimeout() : Promise<void> {
        let count = 0;
        while(count < 4){
            await this.setToken();        
            let config = { ...this.config, timeout: 10000 };
            // eslint-disable-next-line 
            let [err, _] = await to(axios.get(this.getUrl(`/api/cache/`), config))
            if(err){
                console.log(`waitForCacheWithTimeout count:${count}`);
                count++;
            }else{
                console.log(`waitForCacheWithTimeout success`);
                return;
            }
        }
                          
    }

    async cache() : Promise<void> {
        await this.setToken();        
        let [err, response] = await to(axios.get(this.getUrl(`/api/cache/`), this.config))
        if(err){
            console.error(err)
        }else if(response){
            // console.log('cache', response.data)
        }                    
    }
    
}

export default new AdminApi();
