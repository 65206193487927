import React, { useEffect } from 'react';
import {  useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {  SetSurveyById } from '../../actions/SurveyActions';
import SurveyComponent from './SurveyComponent';


interface ViewMessageProps extends RouteComponentProps<{ id: string; }> { }

const EditSurvey: React.FC<ViewMessageProps> = (props:any) => {

  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  const id = props.match.params.id
  const url = props.match.url
  useEffect(()=>{
    
    if(pathname === url){
      console.log('EditSurvey SetSurveyById', `pathname:${pathname} id:${id} url:${url}`)
      dispatch(SetSurveyById(id))      
    }
  // eslint-disable-next-line  
  }, [  id, pathname, url ])
  

  return (
    <SurveyComponent key={props.match.params.id}  />
  );
};


  
  export default EditSurvey;

  