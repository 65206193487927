import React, { useState } from 'react';
import { IonImg, IonButton } from '@ionic/react';
import api from '../../data/api';
import { FaSpinner, FaTimes } from 'react-icons/fa';
import { fileExists, localImageStoragePath, to } from '../../data/util';
import * as storage from '../../data/storage';
import { Photo } from '../../hooks/usePhotoGallery';
import { useFilesystem } from '@ionic/react-hooks/filesystem';
import { FilesystemDirectory, Capacitor } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
import useAsyncEffect from 'use-async-effect';
import log from '../../util/log';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../store/IGlobalState';
import { Role } from '../../data/Role';
const { Filesystem } = Plugins;


interface IProps {    
  userId:string;
  surveyId:string;
  allowDelete:boolean;
  onDelete:(photo:Photo)=>void
  onCropImage:(url:string)=>void
  photo:Photo
  uploading?:boolean
}
const getImageUri = async (userId:string, image:string, surveyId:string) : Promise<string|undefined> =>{        
  let uri = await storage.getItem<string>(localImageStoragePath(image))
    if(uri){      
      console.log(`fetched uri from storage ${uri.substring(0,10)}`)
    }
    if (!uri) {
      uri = await api.getFileUrl(userId, image, surveyId)        
    } 
    if(uri){
      return uri;
    }  
}



const PhotoComponent: React.FC<IProps> = (props:IProps) => {
  
  const [ photo, setPhoto ] = useState<string>();
  const [ error, setError ] = useState<string>();
  const { getUri,readFile } = useFilesystem();
  const user = useSelector((state:IGlobalState)=>state.user);


  useAsyncEffect(async(isMounted)=>{
    
    let uri:string|undefined;
    if(props.photo.webviewPath){
      uri = props.photo.webviewPath
    }else{
      
      try {
        const options = {
          path: props.photo.filepath!,
          directory: FilesystemDirectory.Data
        };
        
        const exists = await fileExists(props.photo.filepath!);                
        
        if(exists){
          const ret = await Filesystem.stat(options);
          if (ret.size) {

            log.info(`file exists locally ${ret.uri}`);
            if (ret.uri.startsWith('/DATA')) {
              const fileReadResult = await Filesystem.readFile(options);
  
              uri = `data:image/png;base64, ${fileReadResult.data}`;
            } else {
              uri = Capacitor.convertFileSrc(ret.uri)
              log.info('new webviewPath: ' + uri);
            }
  
          }
        }
        
      } catch (e) {
        log.error('Unable to stat file');
      }
    }

    if(!isMounted())
      return
    
      
    if(uri){      
      setPhoto(uri)

    }else{      
      const [err, uri] = await to(getImageUri(props.userId, props.photo.filepath!, props.surveyId));      
      if(!isMounted())
        return
      if(err){
        if(err.message){
          setError(err.message)
        }  
        else{
          setError(JSON.stringify(err))
        }
        
      }else{
        const img = new Image();
        img.src = uri!;
        img.onload = () => {
          setPhoto(uri)
        }
      }

    }

    
  
  }, [props.photo, props.surveyId, props.userId, getUri, readFile])
  
  const canCrop = user.roles?.includes(Role.ReadAllSurveys)  
  return (
    <>
    { photo && 
        <div>
        <IonImg src={photo} />
        {props.allowDelete && <FaTimes size={32} className="photos-step__delete-button" onClick={() => props.onDelete(props.photo)} />}
        {props.uploading && <span className="photos-step__saving-info">saving... <FaSpinner className="fa-spin" size={16} /></span>}
        { canCrop && <IonButton size="small" onClick={()=> props.onCropImage(photo) }>Crop Image</IonButton> }
        
      </div>
    }
    {!photo && error && <div className="alert alert-danger">{error}</div>}
    {!photo && !error && <FaSpinner className="fa-spin" size={32} />}
    </>
  );
};

export default PhotoComponent;
