import React from 'react';
import { FaSpinner } from 'react-icons/fa';
interface IProps {
    height?:string
}
const LoadingComponent: React.FC<IProps> = (props:IProps) => {
    const style:any = { }
    if(props.height){
        style.height = props.height
    }
    return (
        <div className="loading" style={style}>
            <FaSpinner className="fa-spin" size={64} />
        </div>
    )
}

export default LoadingComponent;