import { Survey } from '../data/Survey';

export default (state:Survey[]|null = null, action:{type:string, value:Survey[]|null, removed?:string[]}) => {
  
  switch(action.type){    
    case 'SET_SURVEYS':  
    let arr = [ ...(state || [])]
    action.value!.forEach((survey)=>{
      let found = false;
      arr.forEach((existing, index)=>{
        if(survey.id === existing.id){
          arr[index] = survey;
          found=true;
          return;
        }
      })
      if(!found){
        arr.push(survey)
      }
    })
    if(action.removed){
      action.removed.forEach((surveyId)=>{
        for(let i=arr.length-1;i>=0;i--){
          if(arr[i].id===surveyId){
            arr.splice(i, 1);
            break;
          }
        }
      })
    } 
    arr.sort((a,b)=>b.updated.localeCompare(a.updated))
    return arr;
    case 'RESET_SURVEYS':  
      return null
    default:
      return state;
  }
}

