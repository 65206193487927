import { ServiceWorkerState } from '../store/ServiceWorkerState';
import log from '../util/log';

export default (state:ServiceWorkerState = {}, action:{type:string, value:any}) => {
  
  switch(action.type){    
    case 'SW_UPDATE':       
    log.info('SW_UPDATE'+ action.value)
    return {
      ...state,
      serviceWorkerUpdated: true,
      serviceWorkerRegistration: action.value
    };
    
    default:
      return state;
  }
}



export const SwUpdate = (value:any) => {
  return {
      type: 'SW_UPDATE',
      value
  }
}