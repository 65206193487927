import { Role } from "./Role";
import api from "./api";
import { UploadItem, UploadRegistry } from './UploadRegistry';
import { getItem, setItem } from "./storage";
import { Photo } from "../hooks/usePhotoGallery";
import { Survey } from "./Survey";
import { FilesystemDirectory } from "@capacitor/core";
import { Plugins } from '@capacitor/core';
import log from "../util/log";
import { IBase64Data } from "./IBase64Data";

const { Filesystem } = Plugins;

export function firstFileToBase64(fileImage: File): Promise<string> {
    return new Promise((resolve, reject) => {
      let fileReader: FileReader = new FileReader();
      if (fileReader && fileImage != null) {        
        fileReader.readAsDataURL(fileImage);
        fileReader.onload = () => {
          resolve(fileReader.result as string);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject(new Error('No file found'));
      }
    });
  }

  export function to(promise:Promise<any>) {  
    return promise.then(data => {
       return [null, data];
    })
    .catch(err => [err]);
  }

  export function isStandAlone() : boolean {
    const isStandaloneMode = (window.matchMedia('(display-mode: standalone)').matches) 
    || ((window.navigator as any).standalone) 
    || document.referrer.includes('android-app://');
    return isStandaloneMode;
  }

  export function localImageStoragePath(image:string) : string {
    return `${SurveyImageStoragePrefix}${image}`
  }

  export const SurveyImageStoragePrefix = 'surveyimages/';

  export function getRoles(claims:any) : Role[] {
    const roles:Role[] = []
    for(let key in claims){
      for(let role of Object.keys(Role)){
        if(key === role){
          roles.push(role as Role)
          break;
        }
      }     
    }
    return roles;
  }

  export async function isUploadingImages(surveyId:string) : Promise<boolean> {
    const registry = await getItem<UploadRegistry>("UploadRegistry", UploadRegistry as any);
    return registry.items.filter(x=>x.surveyId===surveyId).length > 0;
  }

  export function uploadBase64(base64Data:IBase64Data, item:UploadItem){
    //let prefix = base64Data.startsWith('data:') ? '' : 'data:image/jpeg;base64,' ;
    // fetch(base64Data.data).then(r => {
      
      // r.blob().then((blob)=>{
      //   let file = new File([ blob ], item.image);
         api.uploadFile(new File([ convertToBlob(base64Data, 'image/jpeg') ], item.image), item)
      // })

    // }).catch((e)=>log.error);
  }

  export async function deleteImage (photo:Photo, survey:Survey, onDeleted:(imageName:string)=>void) : Promise<void> {
    if(window.confirm('Are you sure you want to delete this image?')){
      const fileName = photo.filepath!;
      
      await deleteLocalFile(fileName);
      
      const [err] = await to(api.deleteFile(fileName, survey.id, survey.uid))
      if(err){
        log.info('error deleteFile'+ err); //maybe not uploaded?        
      }

      onDeleted(fileName)          
    }
  }

  export async function deleteLocalFile(fileName:string){
    const directory = FilesystemDirectory.Data
    try {
      if(await fileExists(fileName, directory)){
        await Filesystem.deleteFile({
          path: fileName,
          directory
        });
      }
      
    } catch (e) {
      log.error(`Unable to delete file ${fileName}`, e);
    }
  }

  export async function deleteImageFromUploadRegistry(image:string){
    await deleteLocalFile(image);
    const registry = await getItem<UploadRegistry>("UploadRegistry", UploadRegistry as any);      
    const existingItem = registry.items.find(x=>x.image === image);  
    if(existingItem){
        registry.items.splice(registry.items.indexOf(existingItem), 1);
        setItem("UploadRegistry", registry)
      }
  }
  export async function fileExists(name:string, directory:FilesystemDirectory = FilesystemDirectory.Data) : Promise<boolean>{
    try {
      let readdirResult = await Filesystem.readdir({
        path: '',
        directory
      });
      
      return readdirResult.files.find(x=>x===name) != null;
    } 
    catch(e) {
      console.log('Unable to read dir: ' + e);
    }
    return false;
  }


  export function convertToBlob(base64Data: IBase64Data, contentType: string = '', sliceSize: number = 1024): Blob {    
    
    let byteCharacters: string;
    if(base64Data.data.startsWith('data:image')){
      byteCharacters = atob(base64Data.data.substring(base64Data.data.indexOf(',')+1));
    }else{
      byteCharacters = atob(base64Data.data);
    }
    
    const byteArrays: Uint8Array[] = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice: string = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers: number[] = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray: Uint8Array = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob: Blob = new Blob(byteArrays, {type: contentType});
    return blob;
}
