import React from 'react';
import { IGlobalState } from '../../store/IGlobalState';
import { DeleteSurveyImage, AddSurveyImage } from '../../actions/SurveyActions';
import { useSelector, useDispatch } from 'react-redux';
import { usePhotoGallery, Photo } from '../../hooks/usePhotoGallery';
import PhotoComponent from './PhotoComponent';
import {  deleteImage } from '../../data/util';
import log from '../../util/log';
import { AddWebPathAction, DeleteWebPathAction } from '../../actions/WebPathActions';
import { Survey } from '../../data/Survey';
import store from '../../store/configureStore';
import { validator as photosValidator} from './Photos';

import Card from '../common/Card';
import { useHistory } from 'react-router';

const Photos2: React.FC = () => {
  const {  takePhoto } = usePhotoGallery();
  const webPaths = useSelector((state:IGlobalState)=>state.webPaths);
  const survey = useSelector((state:IGlobalState)=>state.survey);
  const dispatch = useDispatch();
  const history = useHistory();  
  

  const takePhotoAndUpload = async ()=>{
    
    const result = await takePhoto(survey);
      console.log('takePhotoAndUpload', result)
      const webPath = { image: result.fileName, path: result.webPath, surveyId: survey.id}
      dispatch(AddWebPathAction(webPath))
     
      await result.savePicture();
      dispatch(DeleteWebPathAction(webPath))    
      dispatch(AddSurveyImage(result.fileName))
   
     
  }

  

  const onDelete = (p:Photo)=>{
    if(p.filepath){
      const existingWebPath = webPaths.find(x=>x.image===p.filepath)
      if(existingWebPath){
        log.info('onDelete: ' + p.filepath)
        dispatch(DeleteWebPathAction(existingWebPath))
      }            
    }
    
    deleteImage(p, survey, (imageName:string)=> dispatch(DeleteSurveyImage(imageName)))
  }

  let images:Photo[] = survey.images.map(image=>{
    return { filepath: image }
  })
  for(let webPath of webPaths){
    if(!survey.images.includes(webPath.image)){
      images.push({ webviewPath: webPath.path })
    }
  }
  
  return <div className='photos-step'>
  <Card>
    <div className='flex-center'>
      <h1 className='details__patient-consent-header'>Photo of each hand</h1>
      <span className='photos-step__help-icon' onClick={()=>history.push("/photo-guide")}>?</span>
      </div>
    
    <span className='details__patient-consent-wording'>Please include one photo EACH of the <em>right and left hand</em>, on a white background with the fingers splayed and wrist visible. Please remove bracelets/watches where possible</span>

    <div className='photos-step__image-container'>
      
      
    { images.length === 0 && <img src="/assets/images/photo_step_background.svg" alt="" /> }

    { images.length > 0 && <div className='photos-step__image-grid'>
      { images.map((photo:Photo, index:any) => {
                          
                          return <div key={index} style={{position: 'relative'}}>
                            <PhotoComponent 
                          
                          onDelete={onDelete} 
                          photo={photo} surveyId={survey.id} 
                          userId={survey.uid} 
                          allowDelete={!survey!.readonly && !!photo.filepath}  
                          uploading={!!!photo.filepath}
                          onCropImage={(p)=>{ }}
                          />
                          </div>
                        
                      })}
      </div>}

      { !survey.readonly && <div className='photos-step__button-panel'>
        <button
          onClick={() => history.push("/photo-guide")}
        >TUTORIAL
        </button>
        <button            
          onClick={async () => await takePhotoAndUpload()}
        >OPEN CAMERA
        </button>
      </div> }
      

    </div>
     


  </Card>
</div>
  
  ;
};


export default Photos2

export const validator = (survey:Survey) : string|undefined =>  {
  const result = photosValidator(survey);
  if(result){
    return result;
  }
  if(store.getState().webPaths.filter(x=>x.surveyId===survey.id).length){
    return 'Please wait for image to save';
  }
}