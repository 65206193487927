import React from 'react';
import StepOptionQuestion, { validator as stepOptionQuestionValidator} from './StepOptionQuestion';
import { StepQuestion, StepQuestionType } from './StepQuestions';
import { Survey } from '../../data/Survey';

const question = new StepQuestion(StepQuestionType.DoctorDiagnosis, "Doctor diagnosis",
   [  "Rheumatoid arthritis", "Psoriatic arthritis", "Osteoarthritis", "Rheumatoid and osteoarthritis", "Psoriatic and osteoarthritis", "Gout", "Other"], 11);

const Diagnosis: React.FC = () => {
  

  return (
    <StepOptionQuestion stepQuestion={question} />
  );
};

export default Diagnosis;

export const validator = (survey:Survey) : string|undefined =>  {
  const error = stepOptionQuestionValidator(survey, question)
  if(!error){
    var item = survey.responses.find(x=>x.questionType===question.type)
    if(item!.response === 'Other' && (survey.otherDiagnosis ||'').trim().length === 0){
      return 'Please provide some details of \'Other\'';      
    }
  }
  
  return error;
}
