import { Survey } from "../data/Survey";

export const setSurveys = (surveys:Survey[], removed:string[]=[]) =>({
    type: 'SET_SURVEYS',
    value: surveys,
    removed
  })
  
  export const resetSurveys = () =>({
    type: 'RESET_SURVEYS',
    value: null
  })