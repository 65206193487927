import React from 'react';
import Card from '../common/Card';

import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../store/IGlobalState';
import { Survey } from '../../data/Survey';
import { EditSurvey } from '../../actions/SurveyActions';
import { Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
//import { CameraPreview, CameraPreviewOptions } from '@capacitor-community/camera-preview';



const Details: React.FC = () => {
  const survey  = useSelector((state:IGlobalState)=>state.survey);
  const dispatch = useDispatch();
  const editSurvey = (updates:{}) => dispatch(EditSurvey(updates))
  
  return <>
    <Card >
      <h1 className='details__patient-consent-header'>Patient consent</h1>
      <div className='flex-center'>
        <Checkbox 
        disabled={survey.readonly}
        checked={survey.acceptedConsent || false}
        onChange={(e) => editSurvey( { acceptedConsent: e.target.checked })}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 34 } }}
        />
        <span className='details__patient-consent-wording'>The patient consents to the use of their hand images, examination result and survey responses, to assist in the development of an arthritis screening tool. These will not be released or identifiable.</span>
      </div>
    </Card>

    <Card>
      <div className='details__main-fields_container'>
        <TextField 
        label="Patient Initials" 
        variant="outlined" 
        disabled={survey.readonly}
          value={survey.initials}
          onChange={(e)=> {
            let value = (e.target.value || '').toUpperCase();          
            editSurvey( { initials: value} )
          }}
        />
        <TextField 
        label="Age" 
        variant="outlined" 
        disabled={survey.readonly}
          type="tel"
          value={survey.age || ''}
          onChange={(e)=> {
            let textVal = e.target.value || '';
            editSurvey( { age: +textVal } )
            
          }}
        />
       
        <RadioGroup
          value={survey.gender}
          onChange={(e) => { editSurvey({ gender: e.target.value }) }}
        >
          <FormLabel>Gender</FormLabel>
          <FormControlLabel value="female" control={<Radio disabled={survey.readonly} />} label="Female" />
          <FormControlLabel value="male" control={<Radio disabled={survey.readonly} />} label="Male" />
        </RadioGroup>
        
      </div>
      
    </Card>
  </>;
};



export const validator = (survey:Survey) : string|undefined =>  {
  if(!survey.initials || survey.initials.length < 2){
    return 'Please provide the patient initials (min 2 characters)'
  }
  if(!survey.gender){
    return 'Please select your gender';    
  }
  if(!survey.age){
    return 'Please provide your age';    
  }
  if(!survey.acceptedConsent){
    return 'Please accept the consent. Results will not be released or identifiable.';    
  }
  
}


export default Details;
