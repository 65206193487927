import Menu from './components/Menu';
import React, { useState } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, IonButton } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
// import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.scss';

import Login from './components/Login';
import SurveyList from './components/SurveyList';
import EditSurvey from './components/survey/EditSurvey';
import NewSurvey from './components/survey/NewSurvey';
import { useSelector } from 'react-redux';
import { IGlobalState } from './store/IGlobalState';
import { updateServiceWorker } from './util/serviceWorkerHelpers';
import { FaSpinner } from 'react-icons/fa';
import UsersListComponent from './admin/components/UsersListComponent';
import UserComponent from './admin/components/UserComponent';
import RouteEx from './pages/RouteEx';
import AboutComponent from './components/about/AboutComponent';
import Dashboard from './pages/Dashboard';
import IntroductionPage from './pages/IntroductionPage';
import InstructionsPage from './pages/InstructionsPage';
import PhotoGuidePage from './pages/PhotoGuidePage';
import { Redirect, Route } from 'react-router-dom';
import { Role } from './data/Role';

interface IProps {
  
}

const App: React.FC = (props:IProps) => {  
  const [ updating, setUpdating ] = useState<boolean>(false)
  const user = useSelector((state:IGlobalState)=>state.user);
  const serviceWorkerState = useSelector((state:IGlobalState)=>state.serviceWorkerState);
  const isBeta = user && !!user.roles?.includes(Role.Beta);
  const redirectTo = isBeta ? '/dashboard' : '/surveys'
  const username =  user == null ? null : user.username; 

  /*
  const mapStateToProps = (state:IGlobalState):any =>{
  return {
    username: state.user == null ? null : state.user.username,
    remoteVersion: state.remoteVersion,
    serviceWorkerState : state.serviceWorkerState
  }
}
  */

  const router = () =>{
    return <IonReactRouter>
      <IonSplitPane contentId="main">
        <Menu />
        <IonRouterOutlet id="main">
          <RouteEx path="/dashboard" component={Dashboard} exact={true} title='Home' />
          <RouteEx path="/introduction" component={IntroductionPage} exact={true} title='Introduction' />
          <RouteEx path="/instructions" component={InstructionsPage} exact={true} title='Instructions' />
          <RouteEx path="/photo-guide" component={PhotoGuidePage} exact={true} title='Photo Guide' />
          <RouteEx path="/surveys" component={SurveyList} exact={true} title='Surveys' />          
          <RouteEx path="/users" component={UsersListComponent} exact={true} title='Users' />
          <RouteEx path="/users/:id" component={UserComponent} exact={true} title='User' />
          <RouteEx path="/about" component={AboutComponent} exact={true} title='About' />
          <Route exact path="/" render={() => <Redirect to={redirectTo} />} />
          <RouteEx path="/new-survey" component={NewSurvey} exact={true} title='New Survey' />          
          <RouteEx path="/survey/:id" component={EditSurvey} exact={true} title='Edit Survey' />
          
        </IonRouterOutlet>
      </IonSplitPane>
    </IonReactRouter>
  }

  
  const isAuthenticated = username != null;
  // const versionMismatch = props.remoteVersion && props.remoteVersion !== environment.version;
  // if(versionMismatch){
  //   return <p>A newer version is available. Please close and reopen this tab to load the latest version</p>
  // }
  if(serviceWorkerState?.serviceWorkerUpdated){
    return <div >
      <p className="text-center">There is a new version available. </p> 
      <IonButton expand="full"
    disabled={updating}
    onClick={async (e)=>{
      setUpdating(true)
      updateServiceWorker(serviceWorkerState.serviceWorkerRegistration);
      //newWorker.postMessage({ action: 'skipWaiting' });
    } }>Update 
    { updating && <FaSpinner className="fa-spin" size={16} style={{marginLeft:'10px'}} /> }
    </IonButton>
    </div>
    
  }
  return (
    // <LoadingComponent />
    <IonApp>
    {/* <Home/> */}
    {isAuthenticated && router()}
    {!isAuthenticated && <Login />}
  </IonApp>
  );
};


export default App;
