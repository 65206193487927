import React from 'react';
import { connect } from 'react-redux';
import { IonList, IonItem, IonButton } from '@ionic/react';
import SurveyListItem from './SurveyListItem';
import { IGlobalState } from '../store/IGlobalState';
import { Survey } from '../data/Survey';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { EditSurvey } from '../actions/SurveyActions';
import api from '../data/api';
import LoadingComponent from './LoadingComponent';
import { IUser } from '../data/IUser';


interface IProps extends RouteComponentProps<{ name: string; }>{
  surveys:Survey[]
  user:IUser
}
const SurveyList: React.FC<IProps> = (props:IProps) => {
 const archiveSurvey = (s:Survey)=>{
  api.archiveSurvey(s);

 }

 const SurveyList = ()=>{
  return <IonList>        

<IonItem><Link to="/new-survey"><IonButton>Scan new patient</IonButton></Link></IonItem>

{props.surveys.length > 0 && <IonItem>Existing Surveys</IonItem>  }
{props.surveys.length ===0 && <IonItem>No Surveys found.</IonItem>  }


  { props.surveys.map((survey:Survey, index:number) => <SurveyListItem key={index} survey={survey} uid={props.user.uid} onDelete={archiveSurvey} />)}

  

  
</IonList>

 }
  return (
    <>
     {!props.surveys && <LoadingComponent />}
      {props.surveys && <SurveyList />} 
    </>
    
  );
};

const mapStateToProps = (state:IGlobalState):any =>{
  return {
    surveys: state.surveys,
    user: state.user
  }
}
const mapDispatchToProps = (dispatch:any):any => {
  return {
    editSurvey:(updates:any, id:string)=>{ dispatch(EditSurvey(updates))}
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SurveyList);
