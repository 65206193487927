import React, { ReactNode } from 'react';
import { StepQuestion } from './StepQuestions';
import {  StepResponse } from '../../data/Survey';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../store/IGlobalState';
import { EditSurvey } from '../../actions/SurveyActions';
import Card from '../common/Card';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';

interface IProps {
  stepQuestion:StepQuestion
  children?: ReactNode
}

const OtherOption:string = 'Other'

const SingleResponseQuestion: React.FC<IProps> = (props:IProps) => {  
  const survey = useSelector((state:IGlobalState)=>state.survey)
  const dispatch = useDispatch();
  let question = props.stepQuestion!;
  let existingResponse = survey.responses.find(x=>x.questionType===question.type)  
  let value = existingResponse?.response as string || '';
  const hasOtherOption = props.stepQuestion.options.includes(OtherOption);
  
  
  let setResponse = (itemSelected:string, otherDiagnosis?:string)=>{
    
    let response:StepResponse = { questionType:question.type, question: question.title, response: itemSelected }
    let otherResponses = survey.responses.filter(x=>x.question !==question.title)
    otherDiagnosis = otherDiagnosis === undefined ? survey.otherDiagnosis : otherDiagnosis;
    const updates:any = { responses: [ ...otherResponses, response ] };
    updates.otherDiagnosis = otherDiagnosis || '';
    dispatch(EditSurvey(updates));
  }

  return <Card>
    <div className='step-option-question__title-container'>
      <span className='step-option-question__num flex-center'>{question.num}</span>
      <span className='step-option-question__header'>{question.title}</span>
    </div>

    <RadioGroup
    value={value}
    onChange={(e) => { setResponse(e.target.value) }}
    className='step-option-question__selection-container'
    >

    { props.children }

    {props.stepQuestion.options.map((option,index)=>{
        return <div key={index}>
          <FormControlLabel 
          value={option}
          control={<Radio disabled={survey.readonly}  
          sx={{
            color: '#9E9E9E',
            '&.Mui-checked': {
              color: '#9E9E9E',
            },
          }}
          />} label={option}
          />
        </div>
      })}     
      
    </RadioGroup>

      { hasOtherOption && <TextField 
        label="Enter other..." 
        variant="outlined" 
        disabled={value!==OtherOption || survey.readonly}         
        value={survey.otherDiagnosis || ''} 
        onChange={(e)=>setResponse(OtherOption, e.target.value || '')}
        />}

        

  </Card>
};



export default SingleResponseQuestion;
