import React from 'react';
import { connect } from 'react-redux';
import {   IonContent,  IonIcon,  IonItem,  IonLabel,  IonList,  IonListHeader,  IonMenu,  IonMenuToggle,  IonNote,} from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {  handLeftOutline, listOutline, helpCircleOutline, peopleOutline, homeOutline  } from 'ionicons/icons';
import { startLogout } from '../actions/UserActions';
import { IGlobalState } from '../store/IGlobalState';
import { useHistory } from "react-router-dom";
import { NewSurveyAction } from '../actions/SurveyActions';
import { IUser } from '../data/IUser';
import { Role } from '../data/Role';


interface MenuProps extends RouteComponentProps {
  user: IUser;
  newSurvey: ()=>void;
}


const Menu: React.FunctionComponent<MenuProps> = (props:MenuProps) => {
  const history = useHistory();
  const { user } = props;
  const isBeta = !!user.roles?.includes(Role.Beta);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList>
          <IonListHeader><a href="/"><h1>HandScan</h1></a></IonListHeader>
          <IonNote>{user.username}</IonNote>
          
          { isBeta && <>
            <IonMenuToggle autoHide={false}>
          <IonItem routerLink="/" routerDirection="none" lines="none" detail={false}>
              <IonIcon slot="start" icon={homeOutline} />
              <IonLabel>Dashboard</IonLabel>
            </IonItem>
          </IonMenuToggle>
          </>}
          

          {user.roles?.includes(Role.CreateSurvey) &&
                   <IonMenuToggle autoHide={false}>
                   <IonItem routerLink="#" routerDirection="none" lines="none" detail={false} onClick={(e)=> {
                     history.push("/new-survey");
                     //newSurvey();
                   } }>
                     <IonIcon slot="start" icon={handLeftOutline} />
                     <IonLabel>New Survey</IonLabel>
                   </IonItem>
                 </IonMenuToggle>
          }

          <IonMenuToggle autoHide={false}>
            <IonItem routerLink="/surveys" routerDirection="none" lines="none" detail={false}>
              <IonIcon slot="start" icon={listOutline} />
              <IonLabel>Existing Surveys</IonLabel>
            </IonItem>
          </IonMenuToggle>


          {user.roles?.includes(Role.SetUsers) &&
                    <IonMenuToggle autoHide={false}>
                    <IonItem routerLink="/users" routerDirection="none" lines="none" detail={false}>
                      <IonIcon slot="start" icon={peopleOutline} />
                      <IonLabel>Users</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
          }

          <IonMenuToggle autoHide={false}>
          <IonItem routerLink="/about" routerDirection="none" lines="none" detail={false}>
              <IonIcon slot="start" icon={helpCircleOutline} />
              <IonLabel>About</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonItem>
          
          </IonItem>
        </IonList>

        
      </IonContent>
    </IonMenu>
  );
};
const mapStateToProps = (state:IGlobalState) =>{
  return {
    user: state.user
  }
}
const mapDispatch = {
  logout: () => (startLogout()),
  newSurvey: () => (NewSurveyAction())
}
export default connect(mapStateToProps, mapDispatch)(withRouter(Menu));
