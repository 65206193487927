import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface IProps {
    value:number
}
const circleOutlineColor = '#C4C4C4'
const CircularProgressWithLabel:React.FC<IProps> = (props:IProps)=> {
  return (
    <div style={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress 
      variant="determinate" 
      value={100} 
      sx={{
        color:circleOutlineColor,
        position: 'absolute'
      }}
      />

      <CircularProgress 
      variant="determinate" 
      value={props.value} 
      sx={{
        color:"var(--ion-color-primary)",
        position: 'absolute'
        
      }}
      />
      <Typography variant="caption" component="div" color={circleOutlineColor}
      sx={{
        position: 'absolute',
        whiteSpace: 'nowrap'
      }}
      >
          {`${props.value} %`}
        </Typography>
    </div>
  );
}


export default CircularProgressWithLabel