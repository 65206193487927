import React from 'react';
import Card from '../components/common/Card';


const Page:React.FC  = () => {
  const steps = [ "Patient consent", "Photo of each hand", "9-part survey", "Single exam result", "Screening result", "Recommendations" ]
  return <div className="content-page">
    <Card>
      <div className='instructions__steps-panel'>
      
      { steps.map((value:string, index:number)=>{
        return <div className='instructions__step' key={index}>
        <span className='instructions__num'>{index+1}</span>
        <span className='instructions__step-text'>{ value }</span>
        { index < steps.length - 1 && <span className='instructions__spacer'></span>}
        </div>
      })}

      </div>
    </Card>
    
  </div>

}

export default Page;