import { useState } from "react";
import { Plugins } from '@capacitor/core';
import { useFilesystem, base64FromPath } from '@ionic/react-hooks/filesystem';
import { isPlatform } from '@ionic/react';
import { CameraResultType, CameraSource, CameraPhoto, Capacitor, FilesystemDirectory, CameraDirection } from "@capacitor/core";
import uuid from 'short-uuid';
import { Survey } from "../data/Survey";
import { uploadBase64 } from "../data/util";
import log from "../util/log";
import { IBase64Data } from "../data/IBase64Data";

const { Camera } = Plugins;

export function usePhotoGallery() {

  const [photos] = useState<Photo[] | undefined>();
  //const { getPhoto } = useCamera();
  const { getUri, readFile, writeFile } = useFilesystem();

  const takePhoto = async (survey:Survey) => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      direction: CameraDirection.Rear,
      quality: 100,
      allowEditing:false,
      saveToGallery:true,
      correctOrientation:true
    });

    
    let fileName = uuid.generate()+ '.jpeg';
        return {
          fileName,
          webPath: photo.webPath!,
          savePicture: () => savePicture(photo, fileName, survey)
        }

  };

  const takePhoto2 = async (survey:Survey, photo:CameraPhoto) => {
    
    let fileName = uuid.generate()+ '.jpeg';
        return {
          fileName,
          webPath: photo.webPath!,
          savePicture: () => savePicture(photo, fileName, survey)
        }

  };

  

  const savePicture = async (photo: CameraPhoto, fileName: string, survey:Survey) => {
    let base64Data:IBase64Data = { data: ''}
    if(photo.base64String){
      //base64Data.data = `data:image/png;base64, ${photo.base64String}`;
      base64Data.data = photo.base64String;
    }else{
    //let base64Data: string = "data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==";
    //"hybrid" will detect Cordova or Capacitor;
      if (isPlatform('hybrid')) {
        log.info('hybrid');
        const file = await readFile({
          path: photo.path!
        });
        base64Data = file;
      } else {            
        base64Data.data = await base64FromPath(photo.webPath!);
      }
    }       
  
    uploadBase64(base64Data, { image: fileName, surveyId: survey.id, meta: { contentType:'image/jpeg' }, userId: survey.uid})
    
    await writeFile({ path: fileName, data: base64Data.data, directory: FilesystemDirectory.Data });
    //console.log('writeFileResult', writeFileResult)
    return getPhotoFile(photo, fileName);
  };


  const getPhotoFile = async (cameraPhoto: CameraPhoto, fileName: string): Promise<Photo> => {
    
    if (isPlatform('hybrid')) {
      // Get the new, complete filepath of the photo saved on filesystem
      const fileUri = await getUri({
        directory: FilesystemDirectory.Data,
        path: fileName
      });

      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      return {
        filepath: fileUri.uri,
        webviewPath: Capacitor.convertFileSrc(fileUri.uri)
      };
    }
    else {
      // Use webPath to display the new image instead of base64 since it's 
      // already loaded into memory
      return {
        filepath: fileName,
        webviewPath: cameraPhoto.webPath
      };
    }
  };


  return {
    photos,
    takePhoto,
    takePhoto2
  };
}

export interface Photo {
  filepath?: string;
  webviewPath?: string;

}

