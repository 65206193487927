import React from 'react';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage,  IonToolbar, IonTitle } from '@ionic/react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../store/IGlobalState';

interface IProps{
  title:string
  component:any
  location:any
  match:any
  exact:boolean
}

const PageComponent: React.FC<IProps> = (props:IProps) => {
  
  
  const isCameraActive = useSelector((state:IGlobalState)=>state.isCameraActive);
  
const Component = props.component;
  
  return (
    <IonPage>
      { !isCameraActive && 
      <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton></IonMenuButton>
          <IonTitle>{props.title}</IonTitle>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    }
      

      

      <IonContent>
      <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{props.title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        {/* {props.children} */}
        <Component key={props.location.key} match={props.match}/>
        
        
      </IonContent>
    </IonPage>
  );
};



export default PageComponent;
