import React from 'react';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
interface IProps {
    step:number
    numSteps:number
    nextPrevClicked:(next:boolean)=>void
    saving:boolean
    prevButtonText:string
    nextButtonText:string    
}



const SurveyButtonPanel:React.FC<IProps> = (props:IProps) => {
    
    const { step, nextPrevClicked, saving, prevButtonText, nextButtonText, numSteps  } = props;
    
    // console.log(`step ${step} of ${numSteps}`)
    const progress = Math.round(step/numSteps*100);

    return <div className={"survey-button-panel"}>
        
        { <button            
            style={{visibility: step < numSteps && nextButtonText ? 'visible':'hidden' }}
            disabled={saving}            
            onClick={() => nextPrevClicked(true)}
        >
            {nextButtonText}</button>}
        
            
            { step !== numSteps - 1 && <CircularProgressWithLabel value={progress} /> } 
            

        { <button
            className='prev-button'
            style={{visibility: step > 0 && prevButtonText ? 'visible':'hidden' }}
            onClick={() => nextPrevClicked(false)}
            disabled={saving}
        >
            {prevButtonText}

        </button>}

        

    </div>
}

export default SurveyButtonPanel