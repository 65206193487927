import { ImageWebPath } from "../data/ImageWebPath"

export const AddWebPathAction = (webPath:ImageWebPath) => {
    return {
        type: 'ADD_WEB_PATH',
        value: webPath
    }
}

export const DeleteWebPathAction = (webPath:ImageWebPath) => {
    return {
        type: 'DELETE_WEB_PATH',
        value: webPath
    }
}