import log from "./log";

export function updateServiceWorker(serviceWorkerRegistration:any)  {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    log.info('updateServiceWorker registrationWaiting'+ registrationWaiting)
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
  
      registrationWaiting.addEventListener('statechange', (e:any) => {
        log.info('registrationWaiting')
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };