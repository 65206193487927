import React from 'react';
import { StepQuestion } from './StepQuestions';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../store/IGlobalState';
import { EditSurvey } from '../../actions/SurveyActions';
import Card from '../common/Card';
import { Checkbox } from '@mui/material';
import { StepResponse } from '../../data/Survey';

interface IProps {
  stepQuestion:StepQuestion
}

const MultiResponseQuestion: React.FC<IProps> = (props:IProps) => {  
  const survey = useSelector((state:IGlobalState)=>state.survey)
  const dispatch = useDispatch();
  

  let existingResponse = survey.responses.find(x=>x.question===props.stepQuestion!.title)
  let isChecked = (option:string) => {
    return existingResponse != null && (existingResponse.response as string).includes(option)
  }
  let question = props.stepQuestion!;


  const onChange = (checked:boolean, title:string) => {
            
    let otherResponses = survey.responses.filter(x=>x.question !== question.title)
            
    let existingResponse = survey.responses.find(x=>x.question===question.title)
    let selections = existingResponse == null ? [] : [ ...(existingResponse.response as string[]) ];

    if(checked){
        if(!selections.includes(title)){
          selections.push(title)
        }
    }else{
      selections = selections.filter(x=>x !== title);
    }
    const newResponse:StepResponse = { questionType:question.type, question: question.title, response: selections }
    const updates = { responses: [ ...otherResponses, newResponse ]};
    console.log('updates', updates)
    dispatch(EditSurvey(updates));
  }
  
  return <Card>
  <div className='step-option-question__title-container'>
    <span className='step-option-question__num flex-center'>{question.num}</span>
    <span className='step-option-question__header'>{question.title}</span>
  </div>

  <div className='step-option-question__selection-container'>

  {props.stepQuestion.options.map((option,index)=>{
        return <div key={index}>
        <Checkbox 
        disabled={survey.readonly}
        checked={isChecked(option)}
        onChange={(e)=> onChange(e.target.checked, option)}
        sx={{ padding: '9px 9px 9px 0', '& .MuiSvgIcon-root': { fontSize: 34 } }}
        />
        <span className='details__patient-consent-wording'>{option}</span>
      </div>
      })}

  </div>

  



      

</Card>;
};



export default MultiResponseQuestion;
