import React from 'react';
import {   IonItem, IonIcon, IonItemSliding, IonItemOptions, IonItemOption   } from '@ionic/react';
import { Survey } from '../data/Survey';
import { maleOutline,femaleOutline } from 'ionicons/icons';
import moment from 'moment';
import { StepQuestionType } from './survey/StepQuestions';
interface IProps {
  survey: Survey;
  uid:string
  onDelete(survey:Survey):void
}

const SurveyListItem: React.FC<IProps> = (props:IProps) => {
  const survey = props.survey;
  const created = moment(survey.created);
  const date = moment(survey.updated);
  const diagnosis = survey.responses.find(x=>x.questionType===StepQuestionType.DoctorDiagnosis);
  let duration = '';
  if(survey.created && survey.completedAt){
    const diff = moment.duration(moment(survey.completedAt).diff(moment(survey.created)));
    duration = diff.humanize(true);
  }
  return (<IonItemSliding>
         
    <IonItem routerLink={`/survey/${survey.id}`}>
      <div className="survey-list-item">
        <IonIcon icon={survey.gender === 'male' ? maleOutline : femaleOutline} style={{ fontSize: '32px' }} color="primary" />

        <div className="survey-list-item__details">
          <div>{survey.initials} <span className="survey-list-item__from-now">{survey.gender} {survey.age}</span></div>
          <div className="survey-list-item__summary">
            {props.uid !== survey.uid && <span className="survey-list-item__email">[{survey.userEmail}]</span>}
            {diagnosis && <span> Diagnosis: <strong>{diagnosis.response}</strong></span>}
            {!survey.readonly && <em> Not Submitted</em>}
            { survey.readonly && duration && <span> Completed: {duration}</span>}

          </div>

        </div>
        <div className="survey-list-item__date">
          <div>{date.format('MMM Do, YYYY')}</div>
          <div className="survey-list-item__from-now">Created:{created.format('D MMM YY')} --  Updated:{date.fromNow()}</div>
        </div>
      </div>


    </IonItem>

          <IonItemOptions side="end">
            <IonItemOption color="danger" onClick={() => props.onDelete(survey)}>Delete</IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
    

  );
};

export default SurveyListItem;
