import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { IonButton } from '@ionic/react';
import uuid from 'short-uuid';
import LoadingComponent from './LoadingComponent';

interface IProps {
    src:string
    saveCrop:(file:File)=>void
    cancelCrop:()=>void
}

const CroppableImage = (props:IProps) => {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState<any>({});  
  //const [previewUrl, setPreviewUrl] = useState();
  const [upImg, setUpImg] = useState<string>('');

  useEffect(()=>{
    const image = new Image();
    image.crossOrigin = 'anonymous'
    image.onload = () => {
      const canvas = document.createElement('canvas');      
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      const context = canvas.getContext('2d');
      context!.drawImage(image, 0, 0);
      const data = canvas.toDataURL('image/jpeg');
      setUpImg(data);
    };

    image.src = props.src;
  }, [ props.src ])

  const onLoad = useCallback(img => {
    imgRef.current = img;
    
  }, []);

  const makeClientCrop = async (crop:any) => {
    if (imgRef.current && crop.width && crop.height) {
      //createCropPreview(imgRef.current, crop);
    }
  };

  const drawCroppedImage = (image:any, canvas:any, filename:string) : Promise<File>=>{
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const cropWidth = crop.width * scaleX;
    const cropHeight = crop.height * scaleY;
    
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext('2d')!;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      cropWidth,
      cropHeight
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob:any) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }        
        blob.name = filename;        
        resolve(blob)
      }, 'image/jpeg');
    });
  }

  // const createCropPreview = async (image:any, crop:any) : Promise<any> => {
  //   const canvas = document.createElement('canvas');    
  //   const blob = await drawCroppedImage(image, canvas, 'preview.jpeg')
  //   window.URL.revokeObjectURL(previewUrl!);
  //   setPreviewUrl(window.URL.createObjectURL(blob) as any);
    
  // };

  
  const saveCrop = async ()=>{    
    if (imgRef.current && crop && crop.width && crop.height) {
        const canvas = document.createElement('canvas');    
        let fileName = uuid.generate()+ '.jpeg';
        const file = await drawCroppedImage(imgRef.current, canvas, fileName)
        props.saveCrop(file)
      }  else{
        alert('No crop selected!')
      }  
  }

  return     <>
      {!upImg && <LoadingComponent height="100%"/>}
      {upImg && <div>
        <ReactCrop
        crossorigin='anonymous'
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}        
        onChange={(crop, percentCrop) => {          
          setCrop(crop)          
        }}
        onComplete={(crop, percentCrop) => {                              
          makeClientCrop(crop)
        }}                
      />
      <div>
      <IonButton size="small" onClick={saveCrop}>Save Crop</IonButton>
      <IonButton size="small" color="danger"  onClick={props.cancelCrop}>Exit Crop Mode</IonButton>
      </div>
      {/* {previewUrl && <img alt="Crop preview" src={previewUrl} />} */}
      
      </div>}
    </>
    
  
}

export default CroppableImage;