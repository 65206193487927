import React, { ReactNode } from 'react';
import { StepQuestion } from './StepQuestions';
import { Survey } from '../../data/Survey';
import SingleResponseQuestion from './SingleResponseQuestion';
import MultiResponseQuestion from './MultiResponseQuestion';

interface IProps {
  stepQuestion:StepQuestion
  children?: ReactNode
}

const StepOptionQuestion: React.FC<IProps> = (props:IProps) => {  
  
  return <>
  { !props.stepQuestion!.multi && <SingleResponseQuestion {...props}/>}
  { props.stepQuestion.multi && <MultiResponseQuestion {...props}/>}
  </>;
};



export default StepOptionQuestion;


export const validator = (survey:Survey, question:StepQuestion) : string|undefined =>  {
  var item = survey.responses.find(x=>x.questionType===question.type)
  if(!question.multi && (!item || !item.response)){
    return 'Please select a single response'
  }
  if(question.multi && (!item || !item.response || !item.response.length)){
    return 'Please select at least one response (multiple selections are possible)';    
  }
  if(question.multi && item != null && item.response.length > 1 && (item.response as string[]).map(x=>x.toLowerCase()).includes('nil')){
    return 'Nil cannot be combined with other answers';    
  }
        
}
