import { Survey, StepResponse } from '../data/Survey';
import { IGlobalState } from '../store/IGlobalState';
import api from '../data/api';
import uuid from 'short-uuid';
import { StepQuestionType } from '../components/survey/StepQuestions';

export const SetSurvey = (survey:Survey) => {
    
    return {
        type: 'SET_SURVEY',
        value: survey
    }
}

export const ResetSurvey = () => {
    
    return {
        type: 'RESET_SURVEY',
        value : null
    }
}

export const NewSurveyAction = () => {
    
    const survey:Survey = { } as Survey
    survey.id = uuid.generate();
    survey.created = new Date().toISOString()
    survey.uid = api.getUserId();
    survey.archived = 0;
    survey.userEmail = api.getUserEmail();
    survey.responses = []
    survey.images = []
    survey.gender = '';
    survey.initials = '';    
    
    return {
        type: 'SET_SURVEY',
        value: survey
    }
}



export const EditSurvey = (updates: any) => {
    return async (dispatch: any, getState: any) => {
        
        updates.updated = new Date().toISOString();
        const state = getState() as IGlobalState;
        const survey = state.survey;
        if(survey.responses.find(x=>x.questionType===StepQuestionType.WristIrritability) == null && updates.responses){      
            let newResponses = updates.responses as StepResponse[];
            if(newResponses.find(x=>x.questionType===StepQuestionType.WristIrritability) != null){
                updates.completedAt = new Date().toISOString();
            }
          }

        await dispatch({
            type: 'EDIT_SURVEY',
            updates
        })                
    }
}


export const StartSaveAction = () => {
    return async (dispatch: any, getState: any) => {
        
        const state = getState() as IGlobalState;
        const survey = state.survey;
        return api.saveSurvey(survey)
    }
}

export const AddSurveyImage = (imageName:string) => {
    return async (dispatch: any, getState: any) => {
        const state = getState() as IGlobalState;
        const survey = state.survey;
        if(!survey.images.includes(imageName)){
            const updates = { images: [ ...survey.images, imageName ] }
            await dispatch(EditSurvey(updates))
            api.updateSurvey(survey, updates)
        }        
    }
}

export const DeleteSurveyImage = (imageName:string, forceSave?:boolean) => {
    return async (dispatch: any, getState: any) => {
        const state = getState() as IGlobalState;
        const survey = state.survey;
        if(survey.images.includes(imageName)){
            const updates = { images: survey.images.filter(x=>x !== imageName) }
            await dispatch(EditSurvey(updates))
            api.updateSurvey(survey, updates,forceSave)
        }        
    }
}


export const StartEditAndSaveSurvey = (updates: any, forceSave?:boolean) => {
    return async (dispatch: any, getState: any) => {
        const state = getState() as IGlobalState;
        await dispatch(EditSurvey(updates))
        api.updateSurvey(state.survey, updates, forceSave)
    }
}

  

export const SetSurveyById = (id: string) => {
    return (dispatch: any, getState: any) => {
        const state = getState() as IGlobalState;
        if(state.surveys){
            let survey = state.surveys.find(x => x.id === id);
            if(survey){
                dispatch(SetSurvey(survey))
            }        
        }
        
    }
}

