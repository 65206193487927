import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../components/common/Card';


const Page:React.FC  = () => {
  const history = useHistory();  
    return <div className='content-page'>
    <Card>
      <p style={{fontWeight: "bold"}}>HandScan is a mobile screening tool for patients presenting with symptoms of hand arthritis</p>
      <ul>
        <li>It is designed to help differentiate between rheumatoid, psoriatic and osteoarthritis, or a combination of conditions</li>
        <li className='introduction__bullet-point'>The process will take 2-3 minutes to complete, following which the screening result will be displayed
Recommendations for diagnostic confirmation and management are outlined.</li>
      </ul>
      

  </Card>

      <div className='introduction__button-panel'>
        <button onClick={() => history.push("/new-survey")}>TAKE SURVEY</button>
      </div>
  
</div>
}

export default Page;