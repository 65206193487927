import { ImageMetadata } from "./ImageMetadata";

export class UploadRegistry {
    items:UploadItem[] = []
}

export interface UploadItem {
  image: string
  surveyId:string
  meta:ImageMetadata
  userId:string

}